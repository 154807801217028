import axios from 'axios';

let loadingBaseData = false;

// lets create the default state
const getInitialState = () => {
  return {
    basedata: null,//null = not loaded, false = not autorized
    monthCount: 3,
  }
}

/** store module **************************************************************************/
export const storeModule = {
  namespaced: true,
  state: getInitialState(),

  // getters => all the public available access points
  getters: {
    basedata: state => state.basedata,
    userId: state => {
      if(state.basedata && state.basedata.user) return state.basedata.user.id;
      return false;
    },
    isLoggedIn: state => !(state.basedata===null || state.basedata===false),
    userCan: (state) => (permission) => {
      if(state.basedata && state.basedata.permissions.indexOf(permission)>-1) return true;//permission found
      return false;
    },
    monthCount: state => state.monthCount,
    dayTypes: () => {
      return [
        { value: 'normal', text : 'Wochentag (20 Uhr - 7 Uhr)', short:'20-7', timeOnly:'von 20 Uhr bis 7 Uhr (des Folgetages)', startHour: 20, endHour: 24+7 },
        { value: 'weekend', text : 'Wochenende (7 Uhr - 7 Uhr)', short:'7-7', timeOnly:'von 7 Uhr bis 7 Uhr (des Folgetages)', startHour: 7, endHour: 24+7 },
        { value: 'special', text : 'Feiertag (7 Uhr - 7 Uhr)', short:'7-7', timeOnly:'von 7 Uhr bis 7 Uhr (des Folgetages)', startHour: 7, endHour: 24+7 },
        { value: 'none', text : 'ohne Dienst', short:'---', timeOnly:'', startHour:0, endHour:0 },
      ]
    },
    dayStates: () => {
      return [
        { value: 'open', text : 'Noch offen' },
        { value: 'requested', text : 'Angefragt' },
        { value: 'denied', text : 'Abgelehnt' },
        { value: 'complete', text : 'Fertig' },
      ];
    },
    
  },

  // mutations => all the things that change the state of the store
  mutations: {
    setBasedata(state, { newValue }) {
      state.basedata = newValue;
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    },
    setMonthCount(state, { newValue }) {
      state.monthCount = Math.abs(newValue);
      window.localStorage.setItem("dentistplan_monthCount", JSON.stringify(state.monthCount));
    },
  },

  // actions => all the actions/functionality the store can perform
  actions: {
    /**
     * fetch the basedata from the API (customers and projects),
     * <code>
     * // fetch
     * this.$store.dispatch('webapp/basedata');
     * </code>
     */
    async basedata({ commit,dispatch }, params) {
      try {
        if(loadingBaseData) return;
        loadingBaseData = true;

        let monthCount = window.localStorage.getItem("dentistplan_monthCount");
        if(monthCount) commit("setMonthCount", { newValue : monthCount });

        const { data } = await axios.get('/api/basedata', { params: { } });
        loadingBaseData = false;
        console.log('[basedata] loaded');
        commit('setBasedata', { newValue: data });
      } catch (e) {
        loadingBaseData = false;
        console.log('[basedata] not found',e);
        commit('setBasedata', { newValue: false });
      }
    },

    setMonthCount({ commit }, params) {
      commit("setMonthCount", { newValue: params });
    },

    resetState({ commit,dispatch }) {
      commit('resetState');
      dispatch('basedata');
    },
  }
}