<!--
HOW TO USE
add MessageConfirm once anywhere inside your vuetify app. 
On a place where it always get loaded, i.e. before the closing "v-app"
<MessageConfirm entrypoint="my_entrypoint"></MessageConfirm>

Now you can use it anywhere in your code:
### promise based
    document.my_entrypoint.openConfirm({ message:"Thats the text" }).then(() => {
      console.log('Confirm closed');
    });

OR
### syncron

    async test() {
      let result = await document.my_entrypoint.openConfirm({ title: "Thats the title", message:"thats the text" });
      console.log('Confirm closed',result);
    }

### Parameters
title (optional)
message
btn_accept (optional) label for accept button
btn_dismiss (optional) label fro dismiss button
type (optional) a class variant added to the dialog
-->
<template>
  <v-dialog v-model="dialog" :content-class="dialogClass" @keydown="handleKeys" persistent max-width="400">
    <v-card>
      <v-card-title class="d-block text-center pa-3 pb-0" v-if="title">{{title}}</v-card-title>
      <v-card-text v-html="message" class="pa-3 text-center"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dismiss" tabindex="0">{{btn_dismiss}}</v-btn>
        <v-btn depressed color="primary" @click="accept" tabindex="0">{{btn_accept}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    entrypoint: {
      required:true,
      type:String
    }
  },
  data:() => ({
    dialog:false,
    message:"",
    title:"",
    btn_accept: "",
    btn_dismiss: "",
    type:""
  }),
  computed: {
    dialogClass() {
      return 'messageConfirmWrap messageConfirmTheme-'+this.type;
    }
  },
  mounted() {
    this.reset();
    document.addEventListener(this.entrypoint+'_open_message_confirm', this.handleOpenEvent, { once:false /* handle listener only once*/ });
    if(document[this.entrypoint]===undefined) document[this.entrypoint] = {};
    document[this.entrypoint].openConfirm = this.publicFunction;
  },
  beforeDestroy() {
    document.removeEventListener(this.entrypoint+'_open_message_confirm', this.handleCloseEvent);
    document[this.entrypoint].openConfirm = undefined;
  },
  methods:{
    handleKeys(event) {
      event.stopPropagation();
      event.preventDefault();
      switch(event.code) {
        case 'Enter': this.accept(); return;
        case 'Escape': this.dismiss(); return;
      }
    },
    reset() {
      this.dialog = false;
      this.message = "";
      this.title = "Hinweis";
      this.btn_accept = "OK";
      this.btn_dismiss = "Abbrechen";
      this.type = "default";
    },
    handleOpenEvent(event) {
      this.dialog = true;
      this.message = event.detail.message;
      if(event.detail.title) this.title = event.detail.title;
      if(event.detail.btn_accept) this.btn_accept = event.detail.btn_accept;
      if(event.detail.btn_dismiss) this.btn_dismiss = event.detail.btn_dismiss;
      if(event.detail.type) this.type = event.detail.type;
    },
    dismiss() {
      /**
       * This function triggers an event on the dom-document.
       * How to listen to:
          document.addEventListener(this.entrypoint+'_message_confirm_close', function(e) {
              console.log(this.entrypoint+'_message_confirm_close', e.detail); // Prints "Example of an event"
          });
      */
      var event = new CustomEvent(this.entrypoint+'_message_confirm_close', { detail: { 'type': 'dismiss' }});
      document.dispatchEvent(event);
      this.reset();
    },
    accept() {
      var event = new CustomEvent(this.entrypoint+'_message_confirm_close',{ detail: { 'type': 'accept' }});
      document.dispatchEvent(event);
      this.reset();
    },


    /**
     * This function will be available via document.<your-entrypoint>.openConfirm
     */
    publicFunction(data) {
      //send open event to MessageConfirm-component 
      document.dispatchEvent(
        new CustomEvent(this.entrypoint+'_open_message_confirm', { detail: data })
      );

      return new Promise((resolve) => {//return promise so the caller can wait for it
        //wait for close event from MessageConfirm-component
        document.addEventListener(this.entrypoint+'_message_confirm_close', (eventData) => {
          if(eventData.detail.type === 'accept') {
            resolve(true);
          } else {
            resolve(false);
          }
        }, { once: true /* handle listener only once*/ });

      });
    }
  }
}
</script>