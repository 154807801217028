<template>
    <div class="markdownEditor">
        <div ref="editorField" class="pell"></div>
    </div>
</template>

<script>
import pell from 'pell';
import 'pell/dist/pell.min.css';
export default {
    props:{
        value: { required: true },
    },
    data:() => ({
        pell:null,
        handleIncommingUpdates:true,
    }),
    watch:{
        value() {
            if(this.handleIncommingUpdates) this.updateValue();
        }
    },
    mounted() {
        this.initWysiwyg();
        this.updateValue();
    },
    beforeDestroy() {},
    methods:{
        updateValue() {
            //posibility to convert some data
            this.pell.content.innerHTML = this.value;
        },
        optimize(value) {
            if(typeof value === 'string') {
                value = value.replace(/&nbsp;/gm,' ');
            }
            return value;
        },
        initWysiwyg() {
            this.pell = pell.init({
                element: this.$refs.editorField,
                onChange: html => {
                    this.handleIncommingUpdates = false;
                    this.$emit('input', this.optimize(html));
                },
                actions: [ /*'paragraph', 'heading1', 'heading2',*/ 
                    {
                        name: 'bold',
                        icon: '<b>F</b>',
                        title: 'Fett',
                    }, {
                        name: 'italic',
                        icon: '<i>K</i>',
                        title: 'Kursiv',
                    }, {
                        name: 'ulist',
                        title: 'Liste',
                        icon: '<i aria-label="Liste" class="v-icon notranslate mdi mdi-format-list-bulleted theme--light" style="font-size: 20px;"></i>'
                    }, {
                        name: 'olist',
                        title: 'Aufzählung',
                        icon: '<i aria-label="Auflistung" class="v-icon notranslate mdi mdi-format-list-numbered theme--light" style="font-size: 20px;"></i>'
                    }, {
                        name: 'link',
                        icon: '<i aria-label="Link" class="v-icon notranslate mdi mdi-link-variant theme--light" style="font-size: 20px;"></i>',
                        result: () => {
                            const url = window.prompt('Geben Sie eine URL ein, um den markierten Text mit einem Link zu versehen.');
                            if (url) pell.exec('createLink', url)
                        }
                    }
                ],
            });
        }
    }
}
</script>

<style lang="scss">
.markdownEditor .pell p { margin:0; }
.markdownEditor .pell-content { background: #fff; height: 200px; }
</style>