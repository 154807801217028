<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <div v-on="on" class="DiagramLinear">
                <!--
                <div class="inner">
                    <v-progress-linear v-if="!days" :width="3" size="20" color="grey" indeterminate class="loadingBar"></v-progress-linear>
                    <div class="diagramPart type_normal" :class="{ minimum: count.normal<0.1 }" :style="{ width: 'calc('+count.normal+' * var(--var-diagram-day-width))' }"></div>
                    <div class="diagramPart type_weekend" :class="{ minimum: count.weekend<0.1 }" :style="{ width: 'calc('+count.weekend+' * var(--var-diagram-day-width))' }"></div>
                    <div class="diagramPart type_special" :class="{ minimum: count.special<0.1 }" :style="{ width: 'calc('+count.special+' * var(--var-diagram-day-width))' }"></div>
                    <div class="diagramPart type_unknown" :class="{ minimum: count.unknown<0.1 }" :style="{ width: 'calc('+count.unknown+' * var(--var-diagram-day-width))' }"></div>
                </div>
                -->
                <div class="inner">
                    <v-progress-linear v-if="!days" :width="3" size="20" color="grey" indeterminate class="loadingBar"></v-progress-linear>
                    <div :class="'diagramPart type_'+days.type"  v-for="(days,$index) in dentistDays" :key="$index" :style="{ width: 'calc(1 * var(--var-diagram-day-width))' }"></div>
                </div>
                <div class="label" v-if="sum>0">{{sum}}&times; in {{year}}</div>
                <div class="label" v-else>keine in {{year}}</div>
            </div>
        </template>
        <span>
            <span v-if="count">
                <strong>{{count.normal.toLocaleString()}}</strong> Wochentag<br />
                <strong>{{count.weekend.toLocaleString()}}</strong> Wochenende<br />
                <strong>{{count.special.toLocaleString()}}</strong> Feiertag<br />
                <v-divider color="#fff"></v-divider>
                <strong>{{sum}}&times;</strong> im Jahr {{year}}<br />
            </span>
            <span v-else>
                lade
            </span>
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props:{
        days:{ required:true, type:Array },
        dentist: { required:true, type:Number },
        year: { required:true, type:Number }
    },
    computed:{
        dentistDays() {
            return this.days.filter(elem => elem.dentist_id === this.dentist).sort((a,b) => {
                if(a.type === b.type) return 0;
                if(a.type === 'normal') return -1;
                if(a.type === 'weekend' && b.type === 'special') return -1;
                if(a.type === 'special') return 1;
                return 0;
            });
        },
        count() {
            let result = {
                normal:0,
                weekend:0,
                special:0,
            }
            this.dentistDays.forEach(elem => { result[ elem.type ]++; });
            return result;
        },
        sum() {
            return this.dentistDays.length;
        },
        percentage() {
            let result = {
                normal:0,
                weekend:0,
                special:0,
                unknown:100
            }
            if(!this.count) return result;
            return result;
        },
    },
}
</script>

<style lang="scss">
.DiagramLinear { 
    .inner {
        height: 20px;  display: flex; position: relative;
        --var-diagram-day-width:1em;
        .loadingBar { position: absolute; }
        .type_normal { background: var(--color-day-normal); border-right: 2px solid #fff; }
        .type_weekend { background: var(--color-day-weekend); border-right: 2px solid #fff; }
        .type_special { background: var(--color-day-special); border-right: 2px solid #fff; }
        .type_unknown { background: #dddddd; }

        .diagramPart { width:0%; transition: width 0.3s ease-in-out; 
            &.minimum { border-right: 0px solid #fff; }
        }
    }
}
</style>