<template>
  <div class="Mail">
    <router-link class="backBtn" :to="{ name:'admin_mailsystem' }">zur Mail-Übersicht</router-link>

    <div v-if="loading">
      <p class="text-center">Daten werden geladen</p>
      <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
    </div>

    <div v-if="entry!==null">
      <h1>
        Mail für das Jahr {{entry.year}}
        <v-btn color="primary" class="ml-1" fab depressed small @click="deleteItem"><v-icon dark>mdi-delete</v-icon></v-btn>
      </h1>
      
      <div>
        <v-chip outlined small v-if="entry.state==='created'" color="warning">Erstellt am {{ this.$helper.format(this.$helper.transformPhpDateString(entry.created_at)) }}</v-chip>
        <v-chip small v-if="entry.state==='send'" color="success">Mails versendet am {{ this.$helper.format(this.$helper.transformPhpDateString(entry.updated_at), "dd.MM.yyyy 'um' HH:mm 'Uhr'") }}</v-chip>
        <EmailLinkDialog class="ml-5" :mailIdentifier="entry.identifier" :dentists="dentists" :randomDentist="randomDentist"></EmailLinkDialog>
        <v-btn :loading="loading" @click="sendTestMail" :disabled="disableInput" class="ml-1">Speichern &amp; Test-E-Mail</v-btn>
      </div>

      <v-alert class="my-3" type="error" v-if="failError">{{failError}}</v-alert>

      <div class="d-flex my-1">
        <v-spacer></v-spacer>
        <v-btn :loading="loading" @click="saveEntry" :disabled="disableInput" class="ml-1">Speichern</v-btn>
        <v-btn :loading="loading" @click="sendMail" :disabled="disableInput" class="ml-1" color="primary">Speichern &amp; E-Mails versenden</v-btn>
      </div>
      <v-divider class="my-5"></v-divider>
      <div>
        <v-btn small class="mr-1" color="primary" outlined :disabled="disableInput" @click="resetDefaultContent('preview')"><v-icon left>mdi-refresh</v-icon> Standard-Texte Vorschau</v-btn>
        <v-btn small class="mr-1" color="primary" outlined :disabled="disableInput" @click="resetDefaultContent('plan')"><v-icon left>mdi-refresh</v-icon> Standard-Texte Endstand</v-btn>
      </div>

      <div class="mt-3">
        <v-text-field v-model="entry.subject" outlined label="E-Mail Betreff" :disabled="disableInput"></v-text-field>
      </div>
      <div>{{textBuildingBlocks}}</div>
      <div class="mt-2">
        <v-textarea outlined v-model="entry.content" label="E-Mail Inhalt" auto-grow :disabled="disableInput"></v-textarea>
      </div>
    </div>


    <template v-if="entry">
      <h4>An diese Ärzte wird die Mail gesendet</h4>
      <v-toolbar v-if="entry" flat class="my-3 doAllowOverflow">
        <v-btn class="ma-1" :disabled="disableInput" @click="selectAllDentists"><v-icon left>mdi-checkbox-multiple-marked</v-icon> alle anwählen</v-btn>
        <v-btn class="ma-1" :disabled="disableInput" @click="selectNoDentists"><v-icon left>mdi-checkbox-multiple-blank-outline</v-icon> keine anwählen</v-btn>
        <v-btn class="ma-1" :disabled="disableInput" @click="selectDefaultDentists"><v-icon left>mdi-calendar-multiselect</v-icon> alle in {{entry.year}} aktiven anwählen</v-btn>
        <v-divider vertical class="mx-2"></v-divider>
        <div>{{entry.dentists.length}} von {{dentists.length}} Ärzten aktiv</div>
        <v-btn small class="ma-1" @click="copyToClipboard"><v-icon left>mdi-content-copy</v-icon> Adressen kopieren</v-btn>
      </v-toolbar>
      <v-row class="dentistList">
        <div class="col-md-6 py-0" v-for="dentist in dentists" :key="dentist.id">
          <v-checkbox v-model="entry.dentists" :value="dentist.id" dense hide-details :disabled="disableInput">
            <template v-slot:prepend>
              <InYear class="ml-4" :days="days" :dentist="dentist" :year="entry.year"></InYear>
            </template>
            <template v-slot:label>
              {{ $helper.getDentistFullName(dentist) }}
              <DentistState class="ml-4" v-if="dentist.state==='inactive'" :dentist="dentist"></DentistState>
            </template>
          </v-checkbox>
        </div>
      </v-row>
    </template>
    
    <debug :data="entry"></debug>
  </div>
</template>

<script>
import { listDentist, readYearDates, readDentistMail, deleteDentistMail, updateDentistMail, createDentistMail, sendDentistMail, sendDentistTestMail } from '../api';
import DentistState from './../DentistState.vue';
import InYear from './InYear.vue';
import EmailLinkDialog from './EmailLinkDialog.vue';
export default {
  components: { DentistState, InYear, EmailLinkDialog },
  props:{
    id:{ required: true, type: Number }
  },
  data:() => ({
    loading:false,
    entry:null,
    formularModal:false,
    dentists:[],
    days:[],

    failError:'',
    errors:[],
  }),
  mounted() { 
    this.loadData();
    this.loadDentists();
  },
  computed:{
    disableInput() {
      if(!this.entry) return true;
      return this.entry.state==='send';
    },
    textBuildingBlocks() {
      return 'Mögliche Textbausteine: {{ANREDE}} {{LINK}}';
    },
    selectedDentists() {
      if(!this.entry.dentists) return [];
      return this.dentists.filter(elem => this.entry.dentists.includes(elem.id));
    },
    randomDentist() {
      if(this.dentists.length===0) return null;
      return this.dentists[ Math.floor( Math.random() * this.dentists.length ) ];
    }
  },
  methods:{
    formularComplete() {
      this.formularModal = false;
      this.loadData();
    },
    loadData() {
      if(this.loading) return;
      this.loading = true;

      this.entry = null;
      readDentistMail(this.id)
        .then(response => {
          this.loading = false;
          this.entry = response.data;
          if(this.entry.dentists===null) this.entry.dentists = [];
          this.loadYear();
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Laden der Daten ist es zu einem Fehler gekommen.", type:"error" });
        })
    },
    saveEntry() {
      if(this.loading) return;
      this.loading = true;
      
      this.errors = [];
      this.failError = '';

      let request = null;
      if(this.entry.id!==null) {
        request = updateDentistMail(this.entry);
      } else {
        request = createDentistMail(this.entry)
      }
      
      request
        .then(response => {
          this.loading = false;
          this.loadData();
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        })
    },
    async sendTestMail() {
      let message = '<p>Die Test E-Mail wird nur an <strong>'+this.entry.contactDentist.firstname+' '+this.entry.contactDentist.lastname+' ('+this.entry.contactDentist.plan_mail+')</strong> versendet.</p>';
      message+= '<p>Sind Sie sicher, dass Sie die Test-E-Mail versenden wollen?</p>'; 
      let result = await document.webapp_messages.openConfirm({ title: "Test-E-Mail versenden?", message: message });
      if(!result) return;

      if(this.loading) return;
      this.loading = true;

      this.failError = '';
      sendDentistTestMail(this.entry)
        .then(response => {
          this.loading = false;
          this.loadData();
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        })
    },
    async sendMail() {
      let message = 'Sind Sie sicher, dass Sie die E-Mail an die angegebenen '+this.entry.dentists.length+' Ärzte versenden wollen?'; 
      let result = await document.webapp_messages.openConfirm({ title: "E-Mail Versenden?", message: message });
      if(!result) return;

      if(this.loading) return;
      this.loading = true;

      this.failError = '';
      sendDentistMail(this.entry)
        .then(response => {
          this.loading = false;
          this.loadData();
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        })
    },
    async deleteItem(item) {
      let message = 'Wollen Sie diese Mail wirklich löschen?'; 
      let result = await document.webapp_messages.openConfirm({ title: "Wirklich löschen?", message: message });
      if(!result) return;

      deleteDentistMail(this.id)
        .then(response => {
          this.$router.push({ name:'admin_mailsystem' });
        })
        .catch(() => {
          document.webapp_messages.openConfirm({ title: "Fehler", message: "Beim Versuch die Mail zu löschen ist es zu einem Fehler gekommen." });
        })
    },
    loadDentists() {
      listDentist()
        .then(response => {
          this.dentists = response.data;
        })
    },
    loadYear() {
      readYearDates(this.entry.year)
        .then(response => {
          this.days = response.data;
        })
    },
    resetDefaultContent(type) {
      this.entry.subject = this.entry.defaults[type].subject;
      this.entry.content = this.entry.defaults[type].content;
    },
    selectDefaultDentists() {
      this.entry.dentists = this.days.map(elem => elem.dentist_id).filter((value, index, self) => self.indexOf(value) === index);//only unique dentist-ids of that year
    },
    selectNoDentists() {
      this.entry.dentists = [];
    },
    selectAllDentists() {
      this.entry.dentists = this.dentists.map(elem => elem.id);
    },
    copyToClipboard() {
      if (!navigator.clipboard) {
        document.webapp_messages.openAlert({ title: "Fehler", message: "Die Funktion zum Speichern in der Zwischenablage wird von ihrem Browser leider nicht unterstützt." });
        return;
      }
      let mailAdresses = [];
      this.selectedDentists.forEach(dentist => {
        mailAdresses.push(this.$helper.getDentistFullName(dentist)+' <'+dentist.plan_mail+'>');
      });
      let text = mailAdresses.join(';');

      navigator
        .clipboard
        .writeText(text)
        .then(() => {
            let message = '<p>In der Zwischenablage befinden sich nun die E-Mail-Adresse aller aktiven Ärzte.</p>';
            message+= '<p>Fügen Sie die Adressen mit <code><strong>STRG + V</strong></code> in das BCC Feld Ihrer E-Mail ein und bestätigen Sie mit <code><strong>ENTER</strong></code>.</p>';
            message+= '<div role="alert" class="mb-0 v-alert v-sheet theme--dark v-alert--border v-alert--border-top red lighten-1"><div class="v-alert__wrapper"><div class="v-alert__content">';
            message+= '<strong>ACHTUNG</strong> wenn Sie eine E-Mail an viele Empfänger schicken sollten Sie <strong>IMMER mittels BCC</strong> (Blind-Carbon-Copy) senden, andernfalls verletzen Sie den Datenschutz!';
            message+= '</div></div></div>';
            document.webapp_messages.openAlert({ title: "E-Mails-Adressen in Zwischenablage", message: message });
          }, 
          () => {
            document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Ablegen in die Zwischenablage ist es zu einem Fehler gekommen." });
          }
        );
    }
  }
}
</script>