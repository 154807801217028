<template>
  <div class="ZahnarztPraxis">
    <router-link class="backBtn" :to="{ name:'admin_zahnarztpraxen' }">Zahnarzt-Übersicht</router-link>

    <div v-if="loading">
      <p class="text-center">Daten werden geladen</p>
      <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
    </div>

    <div v-if="!loading && entry!==null">
      <h1>
        {{ $helper.getDentistFullName(entry) }}
        <v-btn color="primary" class="ml-1" fab depressed small @click="formularModal=true"><v-icon dark>mdi-pencil</v-icon></v-btn>
        <v-btn color="primary" class="ml-1" fab depressed small @click="deleteItem"><v-icon dark>mdi-delete</v-icon></v-btn>
      </h1>
      <p v-if="entry.company">
        Praxis: <strong>{{entry.company}}</strong>
      </p>
      <p>
        {{entry.company_address}}<br />
        {{entry.company_postcode}} {{entry.company_city}}<br />
      </p>

      <div class="mt-1">
        <LinkMaps class="text-decoration-none" :address="entry.company_address" :postcode="entry.company_postcode" :city="entry.company_city" icon-before="mdi-earth-arrow-right"></LinkMaps>
      </div>
      <div v-if="entry.company_mail" class="my-1"><LinkMail class="text-decoration-none" :mail="entry.company_mail" iconBefore="mdi-email"></LinkMail></div>
      <div v-if="entry.company_phone1" class="my-1"><LinkPhone class="text-decoration-none" :number="entry.company_phone1" iconBefore="mdi-phone"></LinkPhone></div>
      <div v-if="entry.company_phone2" class="my-1"><LinkPhone class="text-decoration-none" :number="entry.company_phone2" iconBefore="mdi-phone"></LinkPhone></div>
      <div v-if="entry.company_phone3" class="my-1"><LinkPhone class="text-decoration-none" :number="entry.company_phone3" iconBefore="mdi-phone"></LinkPhone></div>
      <div v-if="entry.company_web" class="my-1"><LinkWebsite class="text-decoration-none" :url="entry.company_web" iconBefore="mdi-web"></LinkWebsite></div>

      <div class="planMail">
        <v-icon>mdi-email</v-icon> {{entry.plan_mail}}<br />
        <small>An diese E-Mail wird der Plan geschickt. Diese E-Mail-Adresse ist nicht öffentlich.</small>
      </div>
      <div>
        Status: <DentistState :dentist="entry"></DentistState>
      </div>
      <small>
        <i v-if="entry.state==='active'">Aktiv -> Der Eintrag wird im Vergabe-System vorausgewählt und ist der Zahnarzt-Auflistung der Patienten erscheinen.</i>
        <i v-else>Inaktiv -> Der Eintrag wird im Vergabe-System NICHT vorausgewählt und nicht in der Zahnarzt-Auflistung der Patienten erscheinen.</i>
      </small>
      <v-divider class="my-5"></v-divider>
      
      <Tage :praxisId="entry.id"></Tage>
    </div>

    
    <v-divider class="my-5"></v-divider>
    
    <debug :data="entry"></debug>

    <v-dialog v-model="formularModal" max-width="700">
      <v-card>
        <v-btn class="modalClose" @click="formularModal=false">&times;</v-btn>
        <v-card-title>Filiale bearbeiten</v-card-title>
        <v-card-text>
          <ZahnarztPraxisFormular @complete="formularComplete" :data="entry"></ZahnarztPraxisFormular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteDentist, readDentist } from '../api';
import ZahnarztPraxisFormular from './ZahnarztPraxisFormular.vue';
import Tage from './Tage.vue';
import DentistState from './../DentistState.vue';
import LinkWebsite from './../LinkWebsite.vue';
import LinkMaps from './../LinkMaps.vue';
import LinkMail from './../LinkMail.vue';
import LinkPhone from './../LinkPhone.vue';
export default {
  components: { ZahnarztPraxisFormular, Tage, DentistState, LinkMaps, LinkMail, LinkPhone, LinkWebsite },
  props:{
    id:{ required: true, type: Number }
  },
  data:() => ({
    loading:false,
    entry:null,
    formularModal:false
  }),
  mounted() { this.loadData(); },
  methods:{
    formularComplete() {
      this.formularModal = false;
      this.loadData();
    },
    loadData() {
      if(this.loading) return;
      this.loading = true;

      this.entry = null;
      readDentist(this.id)
        .then(response => {
          this.loading = false;
          this.entry = response.data;
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Laden der Daten ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
    async deleteItem(item) {
      let message = 'Wollen Sie diese Zahnarztpraxis wirklich löschen?'; 
      let result = await document.webapp_messages.openConfirm({ title: "Wirklich löschen?", message: message });
      if(!result) return;

      deleteDentist(this.id)
        .then(response => {
          this.$router.push({ name:'admin_zahnarztpraxen' });
        })
        .catch(() => {
          document.webapp_messages.openConfirm({ title: "Fehler", message: "Beim Versuch die Praxis zu löschen ist es zu einem Fehler gekommen." });
        })
    },
  }
}
</script>