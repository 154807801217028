<template>
  <div v-if="showDebug">
    <v-btn small depressed class="my-2" @click="details = !details" >{{name}}</v-btn>
    <json-viewer v-if="details" :value="data"></json-viewer>
  </div>
</template>


<script>
export default {
  name: 'debug',
  props:{ 
    data: { required:false },
    name: { required:false, default: 'Debug-Details' }
  },
  computed: {
    showDebug() {
      return this.$store.getters['webapp/userCan']('debug view') && 'true' === window.localStorage.getItem('webapp_debug_view');;
    }
  },
  data:() => ({
    details:false
  })
}
</script>