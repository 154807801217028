<template>
  <div class="createUserWrap">
    <v-card max-width="600px" class="mx-auto mt-10">
      <v-card-title>
        Neuen User Anlegen
      </v-card-title>
      <v-card-text>
        <CreateUser></CreateUser>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CreateUser from "./../../components/baseSystem/CreateUser.vue"
export default {
  components:{ CreateUser }
}
</script>
