<template></template>

<script>
import axios from 'axios';
export default {
  mounted() {
    this.logout();
  },
  methods:{
    logout() {
      axios.post('/logout')
        .then(response => {
          this.$store.dispatch('webapp/resetState');
          this.$router.push({ name: 'home' });
        })
        .catch(error => {})
    },
  }
}
</script>