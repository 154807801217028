<template>
  <v-form @submit.prevent="submit">
    <v-text-field filled type="password" v-model="form.current_password" label="Altes Passwort" :error-messages="errors.current_password"></v-text-field>
    <v-text-field filled type="password" v-model="form.password" label="Neues Passwort" :error-messages="errors.password"></v-text-field>
    <v-text-field filled type="password" v-model="form.password_confirmation" label="Bestätigung Neues Passwort" :error-messages="errors.password_confirmation"></v-text-field>
    
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn type="submit" depressed color="primary">Passwort ändern</v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  data: () => ({
    form:{
      current_password:"",
      password:"",
      password_confirmation:"",
    },
    errors:[],
  }),
  methods:{
    submit() {
      let that = this;
      let request = {
        method: 'PUT',
        url: '/user/password',
        data: this.form
      };
      axios(request)
        .then(response => {
          that.$emit('complete');
        })
        .catch(error => {
          that.errors = error.response.data.errors;
        }); // credentials didn't match
    },
  }
}
</script>