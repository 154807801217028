<template>
  <div class="PreviewSingle page">
    <div v-if="mailIndentifier && dentistUuid">
      <Preview :mailIndentifier="mailIndentifier" :dentistUuid="dentistUuid"></Preview>
    </div>
    <div v-else>
      kein Zugriff
    </div>
  </div>
</template>

<script>
import Preview from '../../components/Zahnarztsystem/Preview/Preview.vue';
export default {
  components:{ Preview },
  data:() =>({
    mailIndentifier:null,
    dentistUuid:null
  }),
  mounted() {
    this.mailIndentifier = this.$route.query.id;
    this.dentistUuid = this.$route.query.dentist;
  }
}
</script>
