<template>
  <div>

    <div v-if="loading">
      <p class="text-center">Daten werden geladen</p>
      <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
    </div>

    <v-alert type="error" v-if="error">{{error}}</v-alert>

    <div v-if="!loading && mainDentist!==null">
      <h3>Übersicht über geplante Bereitschaftsdienste <span v-if="dentistMail && dentistMail.year">{{dentistMail.year}}</span></h3>
      <div class="d-print-none">
        <v-row>
          <div class="col-sm-6">
            <Dentist :dentist="mainDentist"></Dentist>
          </div>
          <div class="col-sm-5 offset-sm-1">
            <v-card>
              <v-card-title class="pb-1">
                <h5>Sie benötigen eine Än&shy;derung?</h5>
              </v-card-title>
              <v-card-text>
                <p class="mb-1">
                  Sollten Sie Tage mit einem anderen Arzt tauschen wollen, so bitte wir Sie selbstständig Kontakt mit dem betreffenden Arzt aufzunehmen und uns die Änderung mitzuteilen.
                </p>
                <v-btn @click="contactDentistModal = !contactDentistModal">unsere Kontaktdaten</v-btn>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </div>

      <v-tabs v-model="tabs" background-color="grey lighten-4" class="mt-5 d-print-none">
        <v-tab>Zugewiesene Tage <strong class="pl-3">{{dentistMail.year}}</strong></v-tab>
        <v-tab>Kalender Ansicht <strong class="pl-3">{{dentistMail.year}}</strong></v-tab>
        <v-tab>Ärzte-Übersicht</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <div v-if="tabs==0">
            <Tage :dentist="mainDentist" :days="days" :year="dentistMail.year"></Tage>
            <BonusTage class="mt-10" :days="daysBonus" :dentist="mainDentist" :year="dentistMail.year+1"></BonusTage>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Calendar v-if="tabs==1" :days="days" :year="dentistMail.year" @dayClick="dayClick"></Calendar>
        </v-tab-item>
        <v-tab-item class="pt-2">
          <DentistList v-if="tabs==2"></DentistList>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-dialog v-model="dayDialog.modal" persistent max-width="450">
      <v-card v-if="dayDialog.day && dayDialog.day.content">
        <v-img :src="require('./../../../assets/images/pexels-cedric-fauntleroy-4269694.jpg')" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
          <v-card-title class="mx-3">
            {{$helper.format(dayDialog.day.content.dateObject, 'EEEEEEE, dd.MM.yyyy')}}
            <div>
              <v-chip color="primary" class="my-1" small><DayType :display="'timeOnly'" :type="dayDialog.day.content.type"></DayType></v-chip>
            </div>
          </v-card-title>
        </v-img>
        <v-btn class="modalClose" dark @click="dayDialog.modal=false">&times;</v-btn>
        <v-card-text>
          <h3 class="my-3">zuständig für den Bereitschaftsdienst</h3>
          <v-divider class="mb-3"></v-divider>
          <Dentist :dentist="dayDialog.day.content.dentist"></Dentist>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contactDentistModal" max-width="500">
      <v-card v-if="contactDentistModal">
        <v-btn class="modalClose" @click="contactDentistModal=false">&times;</v-btn>
        <v-card-title>
          Ansprechpartner für Änderungswünsche
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-3"></v-divider>
          <Dentist :dentist="contactDentist"></Dentist>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getMailInformations } from './../api';
import Tage from './Tage.vue';
import Calendar from './Calendar.vue';
import DayType from './../DayType.vue';
import Dentist from './../Simple/Dentist.vue';
import DentistList from './../Simple/DentistList.vue';
import BonusTage from './BonusTage.vue';
export default {
  components: { Tage, Calendar, DayType, Dentist, BonusTage, DentistList },
  props: {
    mailIndentifier: { required:true, type: String },
    dentistUuid: { required:true, type: String },
  },
  mounted() { this.setup(); },
  data: () =>({
    dentists:null,
    mainDentist:null,
    days:null,
    daysBonus:null,
    dentistMail:null,
    contactDentist:null,
    contactDentistModal:false,
    loading:false,

    tabs:0,

    dayDialog: {
      modal:false,
      day:null
    },
    error:null
  }),
  methods:{
    dayClick(day) {
      if(!day || !day.content) return;
      this.dayDialog.modal = true;
      this.dayDialog.day = day;
    },
    setup() {
      this.loading = true;
      this.error = null;
      getMailInformations(this.mailIndentifier, this.dentistUuid)
        .then(response => {
          this.loading = false;
          this.mainDentist = response.data.dentist;
          this.days = response.data.days;
          this.daysBonus = response.data.daysBonus;
          this.dentists = response.data.dentists;
          this.dentistMail = response.data.dentistMail;
          this.contactDentist = response.data.contactDentist;

          this.days.forEach(elem => {
            elem.dateObject = this.$helper.parseDate(elem.date, 'yyyy-MM-dd');
            elem.dentist = this.dentists.find(d => d.id === elem.dentist_id);
          });
          this.daysBonus.forEach(elem => {
            elem.dateObject = this.$helper.parseDate(elem.date, 'yyyy-MM-dd');
            elem.dentist = this.dentists.find(d => d.id === elem.dentist_id);
          });
        })
        .catch(error => {
          this.loading = false;
          this.error = 'Unter diesem Link konnten keine Daten geladen werden.'
        });
    }
  }
}
</script>