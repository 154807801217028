<template>
  <div class="Jahresplan" v-if="currentMonth">    
    <v-toolbar class="d-print-none" flat dense>
      <v-switch v-model="onlyWithNoDentists" label="Nur offene Tage anzeigen" hide-details></v-switch>
      <v-spacer></v-spacer>
      <template v-if="year && entries">
        <ExcelExport :year="year" :days="currentViewEntries" icon="mdi-table" tooltip="Daten der aktuellen Ansicht als Excel herunterladen"></ExcelExport>
        <ExcelExport :year="year" :days="entries" icon="mdi-table-multiple" :tooltip="'Alle Daten von '+year+' als Excel herunterladen'"></ExcelExport>
        <ExcelBackup :year="year" :days="entries"></ExcelBackup>
        <PrintExport :year="year" :days="entries"></PrintExport>
      </template>
    </v-toolbar>
    <div>
      <v-toolbar dense flat color="primary" class="mb-5 d-print-none">
        <v-toolbar-title style="color:#fff;">{{year}}</v-toolbar-title>
        <v-btn class="ml-2" color="white" small @click="baseCreateYear()"><v-icon>mdi-creation</v-icon></v-btn>
        <!--<v-btn class="ml-2" color="white" small @click="baseCreateYear()"><v-icon>mdi-email-plus</v-icon></v-btn>-->
        
        <v-spacer></v-spacer>
        <v-btn depressed class="mx-1" @click="changeMonth(-12)"><v-icon>mdi-chevron-double-left</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="changeMonth(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-select class="mx-1" outlined dense hide-details style="background:#fff; max-width:10em; border:none; "
            v-model="monthCount" :items="monthCountList">
        </v-select>
        <v-btn depressed class="mx-1" @click="changeMonth(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="changeMonth(12)"><v-icon>mdi-chevron-double-right</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="loadData" :loading="loading"><v-icon>mdi-reload</v-icon></v-btn>
      </v-toolbar>


      <div v-if="loading && !entries" class="my-10">
        <p class="text-center">Daten werden geladen</p>
        <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
      </div>
      <v-row v-else id="jahresplanContent">
        <div :class="colClass" v-for="(m,$index) in months" :key="$index">

          <Monat :month="m" :content="entries" @dayClick="dayClick" @labelClick="labelClick" :valueName="'id'" :class="{ onlyWithNoDentists : onlyWithNoDentists }">

            <template v-slot:content="{ data }">
              <DayType v-if="data.type" class="timeslot" :type="data.type"></DayType>
              <div v-if="data.dentist" class="dentistText">
                <span class="dentistTitle">{{data.dentist.gender}} {{data.dentist.title}}</span> <span class="dentistName">{{data.dentist.firstname}} {{data.dentist.lastname}}</span>
              </div>
              <div v-else class="dentistText">
                noch offen
              </div>
            </template>

          </Monat>
        </div>
      </v-row>

      <v-toolbar v-if="!loading" dense flat color="primary" class="my-5 d-print-none">
        <v-spacer></v-spacer>
        <v-btn depressed class="mx-1" @click="changeMonth(-12)"><v-icon>mdi-chevron-double-left</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="changeMonth(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-select class="mx-1" outlined dense hide-details style="background:#fff; max-width:10em; border:none; "
            v-model="monthCount" :items="monthCountList">
        </v-select>
        <v-btn depressed class="mx-1" @click="changeMonth(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="changeMonth(12)"><v-icon>mdi-chevron-double-right</v-icon></v-btn>
        <v-btn depressed class="mx-1" @click="loadData" :loading="loading"><v-icon>mdi-reload</v-icon></v-btn>
      </v-toolbar>
    </div>

    <v-dialog v-model="dentistModal" max-width="500">
      <v-card>
        <v-btn class="modalClose" @click="dentistModal=false">&times;</v-btn>
        <v-card-text class="pt-4" v-if="dentistModal">
          <TagesFormular @complete="formularComplete" :data="formularDay"></TagesFormular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="yearSetupModal" max-width="500">
      <v-card>
        <v-btn class="modalClose" @click="yearSetupModal=false">&times;</v-btn>
        <v-card-text class="pt-4" v-if="yearSetupModal">
          <VergabeSystem :year="year" @complete="yearSetupComplete"></VergabeSystem>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { readYearDates } from '../api';
import Monat from './Monat.vue';
import TagesFormular from './TagesFormular.vue';
import DayType from '../DayType.vue';
import DayState from '../DayState.vue';
import VergabeSystem from './VergabeSystem.vue';
import ExcelExport from './ExcelExport.vue';
import PrintExport from './PrintExport.vue';
import ExcelBackup from './ExcelBackup.vue';
export default {
  components: { Monat, TagesFormular, DayType, DayState, VergabeSystem, ExcelExport, ExcelBackup, PrintExport },
  props:{
    date:{ required: false, type: Date }
  },
  data:() => ({
    loading:false,
    entries:null,
    currentMonth:null,
    lastLoaded:'',
    monthCountList: [
      { value: 1, text: '1 Monat' },
      { value: 2, text: '2 Monate' },
      { value: 3, text: '3 Monate' },
      { value: 4, text: '4 Monate' },
      { value: 6, text: '6 Monate' },
      { value: 12, text: '12 Monate' },
    ],

    dentistModal: false,
    formularDay: null,

    yearSetupModal: false,
    onlyWithNoDentists: false,
  }),
  computed: {
    monthCount: {
      get: function() { 
        //remove old css classes from body
        this.removeCustomClasses();

        //add css classes with current count to body
        let value = this.$store.getters['webapp/monthCount'];
        document.body.classList.add('monthCount_'+value);
        return value;
      },
      set: function(value) { return this.$store.dispatch('webapp/setMonthCount', value); },
    },
    year() {
      if(!this.startMonth) return null;
      return this.startMonth.getFullYear();
    },
    startMonth() {
      if(!this.currentMonth) return null;
      let startMonth = new Date(this.currentMonth);
      startMonth.setMonth( this.currentMonth.getMonth() - (this.currentMonth.getMonth() % this.monthCount) );
      return startMonth;
    },
    months() {
      let result = [];
      if(this.monthCount===null) return result;
      if(this.startMonth===null) return result;

      result.push(this.startMonth);
      let nextMonth = null;

      for(let i = 1; i<this.monthCount; i++) {
        nextMonth = new Date(this.startMonth);
        nextMonth.setMonth( nextMonth.getMonth() + (i) );
        result.push(nextMonth);
      }
      
      return result;
    },
    currentViewEntries() {
      let neededMonths = this.months.map(month => month.getMonth());
      return this.entries.filter(elem => {
        return neededMonths.includes(elem.dateObject.getMonth())
      });
    },
    colClass() {
      if(!this.monthCount) return { 'col-sm-4': true };
      let result = {};
      result['col-sm-'+ (12/this.monthCount)] = true;
      return result;
    }
  },
  mounted() { this.setup(); },
  beforeDestroy() { this.removeCustomClasses(); },
  methods:{
    removeCustomClasses() {
      this.monthCountList.forEach(elem => {
        document.body.classList.remove('monthCount_'+elem.value);
      });
    },
    setup() {
      this.currentMonth = new Date();
      this.currentMonth.setDate(1);
      this.currentMonth.setHours(12,0,0,0);

      if( this.date !== undefined) {
        this.currentMonth = this.date;
      }
      this.loadData();
    },
    changeMonth(direction) {
      let factor = this.monthCount;
      if(Math.abs(direction)>1) factor = 1;
      this.currentMonth.setMonth( this.currentMonth.getMonth() + (factor * direction) );
      this.currentMonth = new Date(this.currentMonth);
      this.loadData();
    },
    loadData(force) {
      if(this.loading) return;
      if(force) this.lastLoaded = '';
      if(this.lastLoaded===this.currentMonth.getFullYear()) return;
      this.loading = true;

      
      readYearDates(this.currentMonth.getFullYear())
        .then(response => {
          this.loading = false;
          this.lastLoaded = this.currentMonth.getFullYear();
          this.entries = null;
          this.entries = response.data;
          this.entries.forEach(elem => {
            if(!elem.state) elem.state = '';
            elem.cssClass = 'state-'+elem.state;
            elem.cssClass+= ' type-'+elem.type;
            if(elem.dentist) {
              elem.cssClass+= ' dentistExists';
            } else {
              elem.cssClass+= ' noDentistYet';
            }
            elem.dateObject = this.$helper.parseDate(elem.date, 'yyyy-MM-dd', new Date(2000, 1, 1));
          });
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Laden der Daten ist es zu einem Fehler gekommen.", type:"error" });
        })
    },
    
    dayClick(day) {
      if(day.content) {
        this.formularDay = day.content;
        this.dentistModal = true;
      }
    },
    labelClick(day) {
      this.dayClick(day);
    },
    formularComplete(data) {
      this.dentistModal = false;
      this.loadData(true);
    },
    baseCreateYear(month) {
      this.yearSetupModal = true;
    },
    yearSetupComplete() {
      this.loadData(true);
      this.yearSetupModal = false;
    }
  }
}
</script>