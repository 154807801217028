<template>
  <div class="PrivacyPolicy page">
    <h1>Datenschutzerklärung</h1>
    <h2>Datenschutz</h2>
    <p>Die Betreiber dieser Seiten nehmen
      den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
      vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerklärung.
    </p>
    <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe
      personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten
      (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
      möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben.
    </p>
    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet
      (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
      Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
    <p>&nbsp;</p>
    <h2>
      Cookies
    </h2>
    <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem
      Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
      nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
      Rechner abgelegt werden und die Ihr Browser speichert.
    </p>
    <p>Die meisten der von uns verwendeten
      Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
      gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.
      Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
      wiederzuerkennen.
    </p>
    <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen
      von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
      bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der
      Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
      Funktionalität dieser Website eingeschränkt sein.
    </p>
    <p>Quelle: <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">https://www.e-recht24.de/musterdatenschutzerklaerung.
      html</a>
    </p>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>
