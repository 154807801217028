import axios from 'axios';

export const listDentist = () => axios({ method: 'GET', url:'/api/zahnarztsystem/zahnarztpraxis' });
export const createDentist = (data) => axios({ method: 'POST', url:'/api/zahnarztsystem/zahnarztpraxis', data: data });
export const readDentist = (id) => axios({ method: 'GET', url:'/api/zahnarztsystem/zahnarztpraxis/'+id });
export const updateDentist = (data) => axios({ method: 'PUT', url: '/api/zahnarztsystem/zahnarztpraxis/'+data.id, data: data });
export const deleteDentist = (id) => axios({ method: 'DELETE', url: '/api/zahnarztsystem/zahnarztpraxis/'+id });


export const readYearDates = (year) => axios.get('/api/zahnarztsystem/jahresplan', { params: { year: year } });


export const doCreateYear = (data) => axios({ method: 'POST', url: '/api/zahnarztsystem/jahresplan/baseCreateYear', data: data});
export const changeDentistOnDay = (dentist, date) => axios({ method: 'POST', url: '/api/zahnarztsystem/jahresplan/changeDentistOnDay', data: { dentist: dentist, date: date }});

export const createDay = (data) => axios({ method: 'POST', url: '/api/zahnarztsystem/jahresplan/updateDay', data: data });
export const updateDay = (data) => axios({ method: 'PUT', url: '/api/zahnarztsystem/jahresplan/updateDay/'+data.id, data: data });

export const listDentistMail = () => axios({ method: 'GET', url:'/api/zahnarztsystem/dentistmail' });
export const createDentistMail = (data) => axios({ method: 'POST', url:'/api/zahnarztsystem/dentistmail', data: data });
export const readDentistMail = (id) => axios({ method: 'GET', url:'/api/zahnarztsystem/dentistmail/'+id });
export const updateDentistMail = (data) => axios({ method: 'PUT', url: '/api/zahnarztsystem/dentistmail/'+data.id, data: data });
export const deleteDentistMail = (id) => axios({ method: 'DELETE', url: '/api/zahnarztsystem/dentistmail/'+id });

export const sendDentistMail = (data) => axios({ method: 'POST', url: '/api/zahnarztsystem/dentistmail/'+data.id+'/send', data: data });
export const sendDentistTestMail = (data) => axios({ method: 'POST', url: '/api/zahnarztsystem/dentisttestmail/'+data.id+'/send', data: data });
export const duplicateDentistMail = (id) => axios({ method: 'POST', url: '/api/zahnarztsystem/dentistmail/'+id+'/duplicate' });


export const getMailInformations = (mailIdentifier,dentistUuid) => axios({ method: 'GET', url: '/api/zahnarztsystem/dentistmail/'+mailIdentifier+'/'+dentistUuid });

export const nextServices = () => axios({ method: 'GET', url:'/api/zahnarztsystem/nextservices' });
export const simpleListDentist = () => axios({ method: 'GET', url:'/api/zahnarztsystem/dentistlist' });

export const getBoardSettings = () => axios({ method: 'GET', url:'/api/zahnarztsystem/boardsettings/1' });
export const updateBoardSettings = (data) => axios({ method: 'PUT', url:'/api/zahnarztsystem/boardsettings/1', data: data });