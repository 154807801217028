<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="Object.assign(on, slotEvents)" v-bind="attrs"><v-icon>{{icon}}</v-icon></v-btn>
        </template>
        <div>
          {{tooltip}}
        </div>
      </v-tooltip>
    </slot>
  </span>
</template>

<script>
import { listDentist } from '../api';
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
export default {
  props: {
    year: { required:true, type: Number },
    days: { required:true, type: Array },
    icon: { required:false, type: String, default: 'mdi-cloud-download' },
    tooltip: { required:false, type: String, default: 'Backup der Daten des aktuellen Jahres' },
  },
  computed: {
    slotEvents() {
      return { click: this.createXlsx };
    },
    ...mapGetters({
      types : 'webapp/dayTypes',
    }),
  },
  methods: {
    async createXlsx() {
      let dentists = null;
      try {
        let response = await listDentist()
        dentists = response.data;
      } catch(e) {
        document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Laden der Zahnarztpraxen ist ein Fehler aufgetreten.", type:"error" })
        return;
      }
      var book = XLSX.utils.book_new();

      let daysData = [];
      this.days.forEach(elem => {
        daysData.push( {
          'id' : elem.id,
          'date' : elem.date,
          'type' : elem.type,
          'dentist_id' : elem.dentist_id,
          'state' : elem.state,
          'meta' : elem.meta,
          'created_at' : elem.created_at,
          'updated_at' : elem.updated_at,
          'deleted_at' : elem.deleted_at,
        });
      });
      let serviceDaysSheet = XLSX.utils.json_to_sheet(daysData);
      let dentistsSheet = XLSX.utils.json_to_sheet(dentists);
      
      XLSX.utils.book_append_sheet(book, serviceDaysSheet, 'Zahnärztlicher Notfallplan '+this.year);
      XLSX.utils.book_append_sheet(book, dentistsSheet, 'Ärzte');
      XLSX.writeFile(book, 'BACKUP-'+this.year+'-zahnarztsystem---version_'+this.$helper.format(new Date(), 'yyyy-MM-dd_HH-mm')+'.xlsx');
    },
  }
}
</script>