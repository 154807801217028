<template>
  <div class="adminWrap">
    <div class="mb-5">
      <Boardsettings></Boardsettings>
    </div>
  </div>
</template>

<script>
import Boardsettings from '../../components/Zahnarztsystem/Boardsettings.vue'
export default {
  components:{ Boardsettings },
  props:{}
}
</script>
