<template>
  <div class="Jahresplan" v-if="currentMonth">
    <v-toolbar class="d-print-none" flat dense>
      <v-spacer></v-spacer>
      <template v-if="year && days">
        <ExcelExport :year="year" :days="currentViewEntries" icon="mdi-table" tooltip="Daten der aktuellen Ansicht als Excel herunterladen"></ExcelExport>
        <ExcelExport :year="year" :days="days" icon="mdi-table-multiple" :tooltip="'Alle Daten von '+year+' als Excel herunterladen'"></ExcelExport>
        <PrintExport :year="year" :days="days"></PrintExport>
      </template>
    </v-toolbar>
    <div>
      <v-toolbar dense flat color="primary" class="mb-5 d-print-none">
        <v-toolbar-title style="color:#fff;">{{year}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn depressed class="mx-1" @click="changeMonth(-1)" :disabled="currentMonth.getMonth()===0"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-select class="mx-1" outlined dense hide-details style="background:#fff; max-width:10em; border:none; "
            v-model="monthCount" :items="monthCountList">
        </v-select>
        <v-btn depressed class="mx-1" @click="changeMonth(1)" :disabled="currentMonth.getMonth() + monthCount>=12"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-toolbar>


      <div v-if="loading" class="my-10">
        <p class="text-center">Daten werden geladen</p>
        <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
      </div>
      <v-row v-else id="jahresplanContent">
        <div :class="colClass" v-for="(m,$index) in months" :key="$index">

          <Monat :month="m" :content="days" @dayClick="dayClick" @labelClick="labelClick" :valueName="'id'">

            <template v-slot:content="{ data }">
              <DayType v-if="data.type" class="timeslot" :type="data.type"></DayType>
              <div v-if="data.dentist" class="dentistText">
                <span class="dentistTitle">{{data.dentist.gender}} {{data.dentist.title}}</span> <span class="dentistName">{{data.dentist.firstname}} {{data.dentist.lastname}}</span>
              </div>
              <div v-else class="dentistText">
                noch offen
              </div>
            </template>

          </Monat>
        </div>
      </v-row>

      <v-toolbar v-if="!loading" dense flat color="primary" class="my-5 d-print-none">
        <v-spacer></v-spacer>
        <v-btn depressed class="mx-1" @click="changeMonth(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-select class="mx-1" outlined dense hide-details style="background:#fff; max-width:10em; border:none; "
            v-model="monthCount" :items="monthCountList">
        </v-select>
        <v-btn depressed class="mx-1" @click="changeMonth(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import Monat from '../Jahresplan/Monat.vue';
import DayType from '../DayType.vue';
import DayState from '../DayState.vue';
import ExcelExport from '../Jahresplan/ExcelExport.vue';
import PrintExport from '../Jahresplan/PrintExport.vue';
export default {
  components: { Monat, DayType, DayState, ExcelExport, PrintExport },
  props:{
    year:{ required: true, type: Number },
    days:{ required: true, type: Array }
  },
  data:() => ({
    loading:false,
    entries:null,
    currentMonth:null,
    lastLoaded:'',
    monthCountList: [
      { value: 1, text: '1 Monat' },
      { value: 2, text: '2 Monate' },
      { value: 3, text: '3 Monate' },
      { value: 4, text: '4 Monate' },
      { value: 6, text: '6 Monate' },
      { value: 12, text: '12 Monate' },
    ],
  }),
  computed: {
    monthCount: {
      get: function() { 
        //remove old css classes from body
        this.removeCustomClasses();

        //add css classes with current count to body
        let value = this.$store.getters['webapp/monthCount'];
        document.body.classList.add('monthCount_'+value);
        return value;
      },
      set: function(value) { return this.$store.dispatch('webapp/setMonthCount', value); },
    },
    startMonth() {
      if(!this.currentMonth) return null;
      let startMonth = new Date(this.currentMonth);
      startMonth.setMonth( this.currentMonth.getMonth() - (this.currentMonth.getMonth() % this.monthCount) );
      return startMonth;
    },
    months() {
      let result = [];
      if(this.monthCount===null) return result;
      if(this.startMonth===null) return result;

      result.push(this.startMonth);
      let nextMonth = null;

      for(let i = 1; i<this.monthCount; i++) {
        nextMonth = new Date(this.startMonth);
        nextMonth.setMonth( nextMonth.getMonth() + (i) );
        result.push(nextMonth);
      }
      
      return result;
    },
    currentViewEntries() {
      let neededMonths = this.months.map(month => month.getMonth());
      return this.days.filter(elem => {
        return neededMonths.includes(elem.dateObject.getMonth())
      });
    },
    colClass() {
      if(!this.monthCount) return { 'col-sm-4': true };
      let result = {};
      result['col-sm-'+ (12/this.monthCount)] = true;
      return result;
    }
  },
  mounted() { this.setup(); },
  beforeDestroy() { this.tearDown(); },
  methods:{
    tearDown() {
      document.body.classList.remove('calenderView');
      this.removeCustomClasses();
    },
    removeCustomClasses() {
      this.monthCountList.forEach(elem => {
        document.body.classList.remove('monthCount_'+elem.value);
      });
    },
    setup() {
      document.body.classList.add('calenderView');
      this.currentMonth = new Date();
      this.currentMonth.setHours(12,0,0,0);
      this.currentMonth.setFullYear(this.year,0,1);
    },
    changeMonth(direction) {
      let factor = this.monthCount;
      if(Math.abs(direction)>1) factor = 1;
      
      let change = this.currentMonth.getMonth() + (factor * direction);
      if(change<0 || change>11) return;
      this.currentMonth.setMonth( change );
      this.currentMonth = new Date(this.currentMonth);
    },
    dayClick(day) {
      this.$emit('dayClick',day);
    },
    labelClick(day) {
      this.dayClick(day);
    }
  }
}
</script>