<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" ><!-- make this button overrideable -->
      <v-btn @click="logout" v-on="slotEvents">Logout</v-btn>
    </slot>
  </span>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  computed: {
    slotEvents() {
      return { click: this.logout };
    }
  },
  methods:{
    logout() {
      this.$router.push({ name: 'logout' });
    },
  }
}
</script>