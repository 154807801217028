<template>
  <a :href="link" :target="target" :rel="rel">
    <v-icon left v-if="iconBefore">{{iconBefore}}</v-icon>{{text}}<v-icon right v-if="iconAfter">{{iconAfter}}</v-icon>
  </a>
</template>

<script>
export default {
  props:{
    url:{ required: true, type: String },
    target: { required:false, type: String, default:'_blank' },
    rel: { required:false, type: String, default:'external nofollow noopener noreferrer' },
    target: { required:false, type: String, default:'_blank' },
    iconBefore: { required:false, type: [String, Boolean], default:false },
    iconAfter: { required:false, type: [String, Boolean], default:false },
  },
  computed: {
    link() {
      let tmp = this.url.toLocaleLowerCase();
      if(tmp.indexOf('http://') >= 0 || tmp.indexOf('https://') >= 0 ) return tmp;
      return 'https://'+tmp;
    },
    text() {
      let tmp = this.url.toLocaleLowerCase();
      if(tmp.indexOf('http://') >= 0) return tmp.substr(7);
      if(tmp.indexOf('https://') >= 0) return tmp.substr(8);
      return tmp;
    }
  }
}
</script>