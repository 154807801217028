<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="Object.assign(on, slotEvents)" v-bind="attrs"><v-icon>{{icon}}</v-icon></v-btn>
        </template>
        <div>
          {{tooltip}}
        </div>
      </v-tooltip>
    </slot>
  </span>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
export default {
  props: {
    year: { required:true, type: Number },
    days: { required:true, type: Array },
    icon: { required:false, type: String, default: 'mdi-file-excel' },
    tooltip: { required:false, type: String, default: 'Daten als Excel herunterladen' },
  },
  computed: {
    slotEvents() {
      return { click: this.createXlsx };
    },
    ...mapGetters({
      types : 'webapp/dayTypes',
    }),
  },
  methods: {
    createXlsx() {
      var book = XLSX.utils.book_new();

      let data = [];
      this.days.forEach(elem => {
        let currentDay = this.$helper.parseDate( elem.date );
        let tmp = {
          'Tag' : this.$helper.format(currentDay, 'dd.MM.yyyy'),
          'Wochentag' : this.$helper.format(currentDay, 'EEEEEEE'),
        };
        
        if(elem.type) {
          let timeslot = this.types.find(time => time.value === elem.type);
          tmp['Bereitschaftszeit'] = timeslot.timeOnly;
        }

        if(elem.dentist) {
          tmp['Arzt'] = this.$helper.getDentistFullName(elem.dentist);
          tmp['Telefon 1'] = elem.dentist.company_phone1;
          tmp['Telefon 2'] = elem.dentist.company_phone2;
          tmp['Telefon 3'] = elem.dentist.company_phone3;
          tmp['E-Mail'] = elem.dentist.company_mail;
          tmp['Adresse'] = elem.dentist.company_address;
          tmp['PLZ'] = elem.dentist.company_postcode;
          tmp['Stadt'] = elem.dentist.company_city;
          tmp['Web'] = elem.dentist.company_web;
          tmp['Arzt Anrede'] = elem.dentist.gender;
          tmp['Arzt Titel'] = elem.dentist.title;
          tmp['Arzt Vorname'] = elem.dentist.firstname;
          tmp['Arzt Nachname'] = elem.dentist.lastname;
        }
        data.push(tmp);
      });
      
      let newSheet = XLSX.utils.json_to_sheet(data);
      newSheet["!cols"] = [
        { wpx: 70  }, //width of column: Tag
        { wpx: 70  }, //width of column: Wochentag
        { wpx: 200 }, //width of column: Bereitschaftszeit
        { wpx: 250 }, //width of column: Arzt
        { wpx: 100 }, //width of column: Telefon 1
        { wpx: 100 }, //width of column: Telefon 2
        { wpx: 100 }, //width of column: Telefon 3
        { wpx: 150 }, //width of column: E-Mail
        { wpx: 120 }, //width of column: Adresse
        { wpx: 50  }, //width of column: PLZ
        { wpx: 100 }, //width of column: Stadt
        { wpx: 150 }, //width of column: Web
        { wpx: 50  }, //width of column: Arzt Anrede
        { wpx: 70  }, //width of column: Arzt Titel
        { wpx: 100 }, //width of column: Arzt Vorname
        { wpx: 100 }, //width of column: Arzt Nachname
      ];
      
      XLSX.utils.book_append_sheet(book, newSheet, 'Zahnärztlicher Notfallplan '+this.year);
      XLSX.writeFile(book, this.year+'-zahnarzt-nofallplan---version_'+this.$helper.format(new Date(), 'yyyy-MM-dd_HH-mm')+'.xlsx');
    },
  }
}
</script>