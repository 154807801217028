var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MailList"},[_c('h1',[_vm._v("Arzt-Mails")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredData,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"search":_vm.search,"loading-text":"Lade Daten ... Bitte warten Sie"},on:{"click:row":_vm.clickEntry},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('div',{staticClass:"d-flex flex-wrap dataTableTopBar"},[_c('v-btn',{staticClass:"mt-3",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.loadMails}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{staticClass:"mt-3 ml-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.createItem()}}},[_vm._v("neue Mail erstellen")]),_c('v-spacer'),_c('v-data-footer',{staticClass:"noBorder",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})],1)]}},{key:"item.dentists",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dentists.length)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state==='created')?_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"outlined":"","small":"","color":"warning"}},[_vm._v("erstellt")])],1):_vm._e(),(item.state==='send')?_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Mails versendet")])],1):_vm._e(),_c('div',{staticClass:"text-center",staticStyle:{"line-height":"1"}},[_c('small',[_vm._v(_vm._s(_vm.$helper.format(item.relevant_date, 'dd.MM.yyyy HH:mm')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"d-print-none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip_on = ref.on;
var tooltip_attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',tooltip_attrs,false),tooltip_on),[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.clickOnDuplicate(item)}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)],1)]}}],null,true)},[_vm._v(" Eintrag duplizieren ")]),_c('EmailLinkDialog',{attrs:{"mailIdentifier":item.identifier,"dentists":_vm.dentists,"randomDentist":_vm.randomDentist},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"d-print-none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip_on = ref.on;
var tooltip_attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',tooltip_attrs,false),tooltip_on),[_c('v-btn',_vm._b({staticClass:"ml-1",on:{"click":function($event){$event.stopPropagation();return on.click.apply(null, arguments)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-share-outline")])],1)],1)]}}],null,true)},[_vm._v(" Link öffnen, so wie ihn der Zahnarzt sieht. ")])]}}],null,true)}),_c('v-btn',{staticClass:"ml-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.clickEntry(item)}}},[_vm._v("Details")])]}}],null,true)}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer')],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.formularModal),callback:function ($$v) {_vm.formularModal=$$v},expression:"formularModal"}},[(_vm.formularItem===null)?_c('v-card',[_c('v-btn',{staticClass:"modalClose",on:{"click":function($event){_vm.formularModal=false}}},[_vm._v("×")]),_c('v-card-title',[_vm._v("Neuen Mailversand vorbereiten")]),(_vm.formularModal)?_c('v-card-text',[_c('p',[_vm._v("Für welches Jahr soll eine E-Mail versendet werden.")]),_c('div',[_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"text":""},on:{"click":function($event){return _vm.changeYear(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"disabled":"","label":""}},[_vm._v(_vm._s(_vm.year))]),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"text":""},on:{"click":function($event){return _vm.changeYear(1)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.doCreateDentistMail}},[_vm._v("Neuen Mail-Versand vorbereiten")])],1)]):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }