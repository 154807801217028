<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    
    <v-toolbar v-if="isLoggedIn">
      <v-toolbar-items>
        <router-link :to="{ name: 'home' }" class="v-toolbar__title v-btn v-btn--flat text-none px-2">
          <img src="./../assets/images/logo.png" alt="Logo" height="40" /> 
        </router-link>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-toolbar-items v-if="isLoggedIn">
        <router-link :to="{name:'admin_jahresplan'}" custom v-slot="{ href, navigate, isActive, isExactActive }">
          <v-btn :href="href" :color="(isActive || isExactActive) ? 'primary' : 'default'" depressed @click="navigate">Jahresplan</v-btn>
        </router-link>
        <router-link :to="{name:'admin_zahnarztpraxen'}" custom v-slot="{ href, navigate, isActive, isExactActive }">
          <v-btn :href="href" :color="(isActive || isExactActive) ? 'primary' : 'default'" depressed @click="navigate">Zahnärzte</v-btn>
        </router-link>
        <router-link :to="{name:'admin_mailsystem'}" custom v-slot="{ href, navigate, isActive, isExactActive }">
          <v-btn :href="href" :color="(isActive || isExactActive) ? 'primary' : 'default'" depressed @click="navigate">Mail-System</v-btn>
        </router-link>
        <router-link :to="{name:'admin_boardsettings'}" custom v-slot="{ href, navigate, isActive, isExactActive }">
          <v-btn :href="href" :color="(isActive || isExactActive) ? 'primary' : 'default'" depressed @click="navigate"><v-icon>mdi-cog-outline</v-icon></v-btn>
        </router-link>
      </v-toolbar-items>

      <v-toolbar-items v-if="isLoggedIn">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed>
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="$router.push({ name: 'profile' })">
              <v-list-item-icon class="mr-3"><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-title class="pr-5">Profil</v-list-item-title>
            </v-list-item>
            <Logout>
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-icon class="mr-3"><v-icon>mdi-power</v-icon></v-list-item-icon>
                  <v-list-item-title class="pr-5">Logout</v-list-item-title>
                </v-list-item>
              </template>
            </Logout>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn @click="$router.push({ name: 'login' })" depressed>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import Logout from './baseSystem/Logout';
export default {
  components:{ Logout },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'webapp/isLoggedIn'
    }),
  },
  methods: {
    userCan(permission) {
      return this.$store.getters['webapp/userCan'](permission);
    }
  }
}
</script>