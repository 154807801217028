<template>
  <v-form @submit.prevent="submit">
    <v-text-field filled v-model="form.email" name="email" label="E-Mail" :error-messages="errors.email"></v-text-field>
    <v-text-field filled v-model="form.password" name="password" type="password" label="Passwort" :error-messages="errors.password"></v-text-field>
    
    <div class="d-flex flex-wrap">
      <v-btn @click="$router.push({name:'register'})" class="mt-1 mr-1" depressed>Noch nicht registriert?</v-btn>
      <v-btn @click="$router.push({name:'forgot-password'})" class="mt-1" depressed>Passwort vergessen?</v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-1" depressed color="primary" :loading="loading">Login</v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: { 
    msg: { required:false, type:String }
  },
  data: () => ({
    form:{
      email:"",
      password:"",
    },
    loading:false,
    errors:[],
  }),
  methods:{
    async submit() {
      if(this.loading) return;
      this.loading = true;
      try {
        await axios.get('/sanctum/csrf-cookie');//first get the csrf-cookie (will be added in any further requests)
        await axios.post('/login', this.form);
        await this.$store.dispatch('webapp/basedata');
        this.loading = false;
        this.$emit('complete');
      } catch(error) {
        this.loading = false;
        console.log('error while login',error.response);
        this.errors = error.response.data.errors
      }
    },
  }
}
</script>