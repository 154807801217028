<template>
  <span class="DayState" :class="state">
    <v-icon color="info" v-if="state==='open'">mdi-circle-box</v-icon> 
    <v-icon color="warning" v-if="state==='requested'">mdi-help-box</v-icon> 
    <v-icon color="error" v-if="state==='denied'">mdi-close-box</v-icon> 
    <v-icon color="success" v-if="state==='complete'">mdi-check-circle</v-icon> 
  </span>
</template>

<script>
export default {
  props:{
    state:{ required: true, type: String },
  }
}
</script>