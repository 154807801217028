<template>
  <div class="jahresplanWrap page">
    <div class="mb-5">
      <Jahresplan></Jahresplan>
    </div>
  </div>
</template>

<script>
import Jahresplan from './../../components/Zahnarztsystem/Jahresplan/Jahresplan.vue'
export default {
  components:{ Jahresplan },
  props:{
  }
}
</script>
