<template>
  <a :href="link" :target="target" :rel="rel">
    <v-icon left v-if="iconBefore">{{iconBefore}}</v-icon>{{text}}<v-icon right v-if="iconAfter">{{iconAfter}}</v-icon>
  </a>
</template>

<script>
export default {
  props:{
    mail:{ required: true, type: String },
    target: { required:false, type: String, default:'_blank' },
    rel: { required:false, type: String, default:'external nofollow noopener noreferrer' },
    iconBefore: { required:false, type: [String, Boolean], default:false },
    iconAfter: { required:false, type: [String, Boolean], default:false },
  },
  computed: {
    link() {
      let tmp = this.mail.toLocaleLowerCase();
      return 'mailto:'+tmp;
    },
    text() {
      let tmp = this.mail.toLocaleLowerCase();
      return tmp;
    }
  }
}
</script>