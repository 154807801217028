<template>
  <v-autocomplete 
    v-model="data"
    :label="label"
    :items="dentists"
    item-value="id"
    item-text="lastname"
    :filter="filterDentists"
    :hideDetails="hideDetails"
    :outlined="outlined"
    :returnObject="returnObject"
    :error-messages="errorMessages"
    filled
    @click="clickAutocomplete"
    clearable
  >
    <template v-slot:item="{item}">{{$helper.getDentistFullName(item)}}</template>
    <template v-slot:selection="{item}">{{$helper.getDentistFullName(item)}}</template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    dentists: { required:true, type:Array },
    value: { required:false, type:[Number,Object] },
    label: { required:false, type:String, default: 'Zahnarzt auswählen' },
    hideDetails: { required:false, type:Boolean, default:false },
    outlined: { required:false, type:Boolean, default:false },
    returnObject: { required:false, type:Boolean, default:false },
    errorMessages: { required:false },
  },
  computed: {
    data: {
      get() { return this.value; },
      set(newValue) { this.$emit('input',newValue); },
    },
  },
  methods: {
    filterDentists(item, queryText, itemText) {
      let search = queryText.toLocaleLowerCase();
      return item.firstname.toLocaleLowerCase().indexOf(search) > -1
        || item.lastname.toLocaleLowerCase().indexOf(search) > -1
      ;
    },
    clickAutocomplete() {
      this.data = null;
    }
  }
}
</script>