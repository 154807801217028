<template>
  <div class="loginWrap">
    <v-card max-width="600px" class="mx-auto mt-10">
      <v-card-text>
        <h3 class="mb-5 text-center">Login</h3>
        <Login @complete="$router.push({name:'home'})"></Login>
      </v-card-text>
    </v-card>
    <div class="text-center mt-3">
      <router-link :to="{name:'home'}">zur Startseite</router-link>
    </div>
  </div>
</template>

<script>
import Login from "./../../components/baseSystem/Login.vue"
export default {
  components:{ Login }
}
</script>
