<template>
  <div>
    <h1>Einstellungen</h1>
    <v-form @submit.prevent="storeData">
      <v-alert class="ma-0" v-if="errorMessage" type="error" text dense>{{errorMessage}}</v-alert>
      <div class="my-10">
        <h3 class="mb-2">Organisator</h3>
        <DentistAutocomplete label="Organisator"
          v-model="settings.mainDentist"
          :dentists="dentists"
          :error-messages="errors.mainDentist"
        ></DentistAutocomplete>
        <p>
          Die Kontaktdaten des Organisators  werden den Ärzten bei den Änderungsanfragen angezeigt. Außerdem ist die Plan-E-Mail-Adresse des Organisators die automatische Antwortaddresse der versendeten E-Mails.
        </p>
      </div>
      <div>
        <h3 class="mb-2">Nachricht im Frontend</h3>
        <HtmlEditor v-if="!showHtml" v-model="settings.messageBox"></HtmlEditor>
        <v-textarea v-if="showHtml" v-model="settings.messageBox" label="Html-Ansicht" outlined hide-details></v-textarea>
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-checkbox hide-details v-model="showHtml" label="HTML anzeigen?"></v-checkbox>
          <v-btn class="ml-3" @click="settings.messageBox=''; showHtml=false;">Inhalt löschen?</v-btn>
        </v-toolbar>

        <h3>Nachricht anzeigen?</h3>
        <v-switch class="mt-0" dense hide-details v-model="settings.showMessageBox" :label=" settings.showMessageBox ? 'Nachricht wird angezeigen' : 'Nachricht wird nicht angezeigen'"></v-switch>
      </div>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-btn class="mt-10" type="submit" color="primary">Speichern</v-btn>
      </v-toolbar>
    </v-form>
  </div>
</template>

<script>
import { getBoardSettings, updateBoardSettings, listDentist } from './api';
import DentistAutocomplete from './DentistAutocomplete.vue';
import HtmlEditor from './HtmlEditor.vue';
export default {
  components: { DentistAutocomplete,HtmlEditor },
  mounted() { this.setup() },
  data: () => ({
    settings:{},
    dentists:[],
    errors:{},
    errorMessage:'',
    loading:false,
    showHtml:false,
  }),
  methods:{
    setup() {
      this.setDefaultData();
      this.loadData();
      this.loadDentists();
    },
    loadDentists() {
      listDentist()
        .then(response => {
          this.dentists = response.data;
        })
    },
    setDefaultData() {
      this.settings = {
        messageBox: '',
        showMessageBox: false,
        mainDentist: null,
      };
    },
    async loadData() {
      if(this.loading) return;
      this.loading = true;
      try {
        let response = await getBoardSettings();
        this.assignData(response.data);
      } catch(e) {
        this.settings = {};
      }
      this.loading = false;
    },
    async storeData() {
      if(this.loading) return;
      this.loading = true;
      this.errors = {};
      this.errorMessage = '';
      try {
        await updateBoardSettings( this.settings );
      } catch(e) {
        if(e.response.data.errors) this.errors = e.response.data.errors;
        this.errorMessage = 'Beim Speichern ist ein Fehler aufgetreten.';
      }
      this.loading = false;
    },
    assignData(data) {
      let importData = {};
      if(typeof data.settings === 'string') importData = JSON.parse(data.settings);
      if(importData.messageBox !== undefined) this.settings.messageBox = importData.messageBox;
      if(importData.mainDentist !== undefined) this.settings.mainDentist = importData.mainDentist;
      if(importData.showMessageBox !== undefined) this.settings.showMessageBox = !!importData.showMessageBox;
    }
  }
}
</script>