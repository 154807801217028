<template>
  <div class="Dentist SimpleDentistDetails">
    <h2 class="mb-3">
      {{ $helper.getDentistFullName(dentist) }}
    </h2>
    <div class="innerInfo">
      <p v-if="dentist.company" class="mb-1">
        Praxis: <strong>{{dentist.company}}</strong>
      </p>
      <div>{{dentist.company_address}}</div>
      <div>{{dentist.company_postcode}} {{dentist.company_city}}</div>
      <div class="mt-1">
        <v-chip color="grey lighten-3"><LinkMaps class="text-decoration-none" :address="dentist.company_address" :postcode="dentist.company_postcode" :city="dentist.company_city" icon-before="mdi-earth-arrow-right"></LinkMaps></v-chip>
      </div>
      
      <div v-if="dentist.company_mail && !hideMail" class="my-1"><v-chip color="primary"><LinkMail :mail="dentist.company_mail" iconBefore="mdi-email"></LinkMail></v-chip></div>
      <div v-if="dentist.company_phone1" class="my-1"><v-chip color="primary"><LinkPhone :number="dentist.company_phone1" iconBefore="mdi-phone"></LinkPhone></v-chip></div>
      <div v-if="dentist.company_phone2" class="my-1"><v-chip color="primary"><LinkPhone :number="dentist.company_phone2" iconBefore="mdi-phone"></LinkPhone></v-chip></div>
      <div v-if="dentist.company_phone3" class="my-1"><v-chip color="primary"><LinkPhone :number="dentist.company_phone3" iconBefore="mdi-phone"></LinkPhone></v-chip></div>
      <div v-if="dentist.company_web && !hideWeb" class="my-1"><v-chip color="primary"><LinkWebsite :url="dentist.company_web" iconBefore="mdi-web"></LinkWebsite></v-chip></div>
    </div>

  </div>
</template>

<script>
import LinkWebsite from './../LinkWebsite.vue';
import LinkPhone from './../LinkPhone.vue';
import LinkMail from './../LinkMail.vue';
import LinkMaps from './../LinkMaps.vue';
export default {
  components: { LinkWebsite, LinkPhone, LinkMail, LinkMaps },
  props: {
    dentist: { required: true },
    hideWeb: { required: false, type:Boolean, default: false },
    hideMail: { required: false, type:Boolean, default: false },
  }
}
</script>