<template>
  <div class="ZugewieseneTage">
    <v-toolbar dense flat color="primary" class="mb-2">
      <v-toolbar-title style="color:#fff;">Zugewiesene Tage <strong>{{currentYear}}</strong></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mx-1" @click="changeYear(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-btn class="mx-1" disabled dark>{{currentYear}}</v-btn>
      <v-btn class="mx-1" @click="changeYear(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
      <v-btn class="mx-1" @click="loadData(true)" :loading="loading"><v-icon>mdi-reload</v-icon></v-btn>
    </v-toolbar>


    <v-container fluid>
      <v-row>
        <div class="col-md-6">
          <div class="dateListing" v-if="dentistEntries">
            <div class="listEntry" v-for="d in dentistEntries" :key="d.id" :class="cssClass(d)">
              <div class="dateListingType">
                <DayType :type="d.type" ></DayType>
              </div>
              <div class="dateListingContent d-flex">
                {{ $helper.format(d.dateObject, 'dd.MM.yyyy (EEEE)') }}
                <v-spacer></v-spacer>
                
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small class="ml-1" color="primary" @click="assignOtherDentist(d)"><v-icon small>mdi-arrow-right-bold</v-icon></v-btn>
                  </template>
                  <div>Diesen Tag einem anderen Zahnarzt zuweisen</div>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small class="ml-1" color="primary" @click="modalSwapDentists(d)"><v-icon small>mdi-arrow-left-right-bold</v-icon></v-btn>
                  </template>
                  <div>Mit dem Tag eines anderen Zahnarztes tauschen.</div>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small class="ml-1" color="primary" @click="deleteDay(d)"><v-icon small>mdi-delete</v-icon></v-btn>
                  </template>
                  <div>Die Zuordnung löschen und den Tag offen lassen.</div>
                </v-tooltip>
              </div>
            </div>
          </div>

          <template v-if="currentYear && entries">
            <SelectDay :year="currentYear" :daysOfYear="entries" :ignoreDentistsById="[praxisId]" @complete="addNewEntry"></SelectDay>
          </template>
        </div>


        <div class="col-md-6 stats">
          <h3>Auswertung</h3>
          <template v-if="dentistEntries">
            <div>
              <v-chip label>{{dentistEntries.length}} x</v-chip> Insgesamt zugewiesen
            </div>
            <div v-if="dentistEntries.length>0">
              <v-divider class="my-1"></v-divider>
              <div class="my-1 type-normal"><v-chip small label>{{dayType_normal}} x</v-chip> an Wochentagen</div>
              <div class="my-1 type-weekend"><v-chip small label>{{dayType_weekend}} x</v-chip> an Wocheenden</div>
              <div class="my-1 type-special"><v-chip small label>{{dayType_special}} x</v-chip> an Feiertagen</div>
            </div>
          </template>
        </div>
      </v-row>
    </v-container>

    <v-dialog v-model="formularModal" max-width="500">
      <v-card>
        <v-btn class="modalClose" @click="formularModal=false">&times;</v-btn>
        <v-card-text class="pt-4" v-if="formularModal">
          <TagesFormular @complete="formularComplete" :data="formularDay"></TagesFormular>
        </v-card-text>
      </v-card>
    </v-dialog>

    
    <v-dialog v-model="assignOther.modal" max-width="500">
      <v-card>
        <v-btn class="modalClose" @click="assignOther.modal=false">&times;</v-btn>
        <v-card-title>Wählen Sie einen Zahnarzt</v-card-title>
        <v-card-text class="pt-4" v-if="assignOther.modal">
          <DentistAutocomplete v-model="assignOther.dentist" :dentists="dentists" label="Diesem Zahnarzt zuweisen"></DentistAutocomplete>
          <v-btn @click="complete_assignOtherDentist">Übernehmen</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="swapDentists.modal" content-class="SwapDentistModal" max-width="500">
      <v-card>
        <v-btn class="modalClose" @click="swapDentists.modal=false">&times;</v-btn>
        <v-card-title>Tag mit anderem Zahnarzt wechseln</v-card-title>
        <v-card-text class="pt-4" v-if="swapDentists.modal">
          <p>Wählen Sie den Zahnarzt der den <strong>{{$helper.format(swapDentists.dateOld, 'dd.MM.yyyy')}} ({{$helper.format(swapDentists.dateOld, 'EEEEEEE')}})</strong> bekommen soll:</p>
          <DentistAutocomplete v-model="swapDentists.dentist" :dentists="dentists" label="Diesem Zahnarzt zuweisen"></DentistAutocomplete>

          <v-slide-y-transition>
            <div class="dateListing" v-if="swapDentistEntries">
              <p>Wählen Sie den Tag der dafür übernommen werden soll:</p>
              <div class="listEntry" v-for="d in swapDentistEntries" :key="d.id" :class="cssClass(d)">
                <div class="dateListingType">
                  <DayType :type="d.type" ></DayType>
                </div>
                <div class="dateListingContent d-flex">
                  {{ $helper.format(d.dateObject, 'dd.MM.yyyy (EEEE)') }}
                  <v-spacer></v-spacer>
                  <v-btn color="primary" x-small @click="complete_swapDentist(d)">Übernehmen</v-btn>
                </div>
              </div>
            </div>
          </v-slide-y-transition>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { listDentist, changeDentistOnDay, readYearDates } from '../api';
import DayType from './../DayType.vue';
import { parse } from 'date-fns';
import DayState from './../DayState.vue';
import TagesFormular from './../Jahresplan/TagesFormular.vue';
import DentistAutocomplete from './../DentistAutocomplete.vue';
import SelectDay from './SelectDay.vue';
export default {
  components: { DayType,DayState,TagesFormular,DentistAutocomplete, SelectDay },
  props: {
    praxisId: { required:true }
  },
  data: () => ({
    currentYear:null,
    lastLoaded:'',
    loading:false,
    entries: null,
    dentiests: [],

    newEntryDate:'',

    assignOther : {
      modal:false,
      dentist:null,
      date:null,
    },

    swapDentists : {
      modal:false,
      dentist:null,
      dateOld:null,
      dateNew:null,
    },


    formularModal: false,
    formularDay: null,
  }),
  computed: {
    dentistEntries() {
      if(!this.entries) return this.entries;
      return this.entries.filter(elem => elem.dentist_id===this.praxisId);
    },
    swapDentistEntries() {
      if(!this.entries || !this.swapDentists.dentist) return null;
      return this.entries.filter(elem => elem.dentist_id===this.swapDentists.dentist);
    },
    dayType_normal() { if(this.dentistEntries) return this.dentistEntries.filter(elem => elem.type==='normal').length; },
    dayType_weekend() { if(this.dentistEntries) return this.dentistEntries.filter(elem => elem.type==='weekend').length; },
    dayType_special() { if(this.dentistEntries) return this.dentistEntries.filter(elem => elem.type==='special').length; },
  },
  mounted() {
    this.currentYear = (new Date()).getFullYear();
    this.loadData();
    this.loadDentists();
  },
  methods: {
    assignOtherDentist(day) {
      this.assignOther = {
        modal: true,
        dentist: null,
        date: day.dateObject,
      };
    },
    complete_assignOtherDentist() {
      if(!this.assignOther.dentist) return;
      if(this.loading) return;
      this.loading = true;


      changeDentistOnDay(this.assignOther.dentist, this.$helper.format(this.assignOther.date,'yyyy-MM-dd'))
        .then(response => {
          this.loading = false;
          this.assignOther.modal = false;
          this.loadData(true);
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Ändern ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
    modalSwapDentists(day) {
      this.swapDentists = {
        modal: true,
        dentist: null,
        dateOld:day.dateObject,
        dateNew:null,
      };
      
      return;
    },
    async complete_swapDentist(day) {
      this.swapDentists.dateNew = day.dateObject;
      this.swapDentists.modal = false;

      if(this.loading) return;
      this.loading = true;

      try {
        await changeDentistOnDay(this.swapDentists.dentist, this.$helper.format(this.swapDentists.dateOld,'yyyy-MM-dd'));
        await changeDentistOnDay(this.praxisId, this.$helper.format(this.swapDentists.dateNew,'yyyy-MM-dd'));
      } catch(e) {
        document.webapp_messages.openAlert({ message:"Beim Ändern ist es zu einem Fehler gekommen.", type:"error" });
      }

      this.assignOther.modal = false;
      this.loading = false;
      this.loadData(true);
    },
    async deleteDay(day) {
      if(this.loading) return;
      if(! await document.webapp_messages.openConfirm({ message: "Sind Sie sicher, dass die Zuordnung dieses Tages gelöscht werden soll? Der Tag ist dann nicht mehr durch einen Zahnarzt besetzt." })) {
        return;
      }
      this.loading = true;
      
      changeDentistOnDay(null, this.$helper.format(day.dateObject, 'yyyy-MM-dd'))
        .then(response => {
          this.loading = false;
          this.loadData(true);
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message: "Beim Anlegen ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
    async addNewEntry(isValidNewEntry) {
      if(!isValidNewEntry.date) return;
      if(!isValidNewEntry.result && isValidNewEntry.dentist) {
        if(!await document.webapp_messages.openConfirm({ message: "Sind sie Sicher, das Sie den bestehenden Eintrag überschrieben wollen?"})) {
          return;
        }
      }
      if(this.loading) return;
      this.loading = true;
      
      changeDentistOnDay(this.praxisId, this.$helper.format(isValidNewEntry.date,'yyyy-MM-dd'))
        .then(response => {
          this.loading = false;
          isValidNewEntry.clearInput();
          this.loadData(true);
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Anlegen ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
    loadData(force) {
      if(this.loading) return;
      if(force) this.lastLoaded = '';
      if(this.lastLoaded===this.currentYear) return;
      this.loading = true;

      readYearDates(this.currentYear)
        .then(response => {
          this.lastLoaded = this.currentYear;
          this.loading = false;
          this.entries = response.data;
          this.entries.forEach(elem => {
            elem.dateObject = parse(elem.date, 'yyyy-MM-dd', new Date(2000, 1, 1));
          });
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Beim Laden der Daten ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
    changeYear(direction) {
      this.currentYear+= direction;
      this.loadData();
    },
    cssClass(data) {
      let result = {};
      if(data && data.type) {
        result['type-'+data.type] = true;
      }
      if(data && data.state) {
        result['state-'+data.state] = true;
      }
      return result;
    },
    dayClick(day) {
      if(day) {
        this.formularDay = day;
        this.formularModal = true;
      }
    },
    formularComplete(data) {
      this.formularModal = false;
      this.loadData(true);
    },
    loadDentists() {
      listDentist()
        .then(response => {
          this.dentists = response.data;
        });
    }
  }
}
</script>