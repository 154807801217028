<template>
    <transition name="fade" mode="in-out">
        <v-overlay :absolute="true" :opacity="opacity" color="#fff" :value="show">
            <v-progress-circular :width="3" :color="color" indeterminate ></v-progress-circular>
        </v-overlay>
    </transition>
</template>

<script>
export default {
    props: {
        show: {
            required:true,
            type: Boolean,
        },
        opacity: {
            required: false,
            type: Number,
            default: .5,
        },
        color:{
            required: false,
            type: String,
            default: "primary"
        }
    },
    data:() => ({
        visible:false
    }),
    mounted() {
        this.visible = true;
    }

}
</script>
