<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" ><!-- make this button overrideable -->
      <v-btn v-on="slotEvents">Cache zurücksetzen</v-btn>
    </slot>
  </span>
</template>

<script>
import axios from 'axios';
export default {
  computed: {
    slotEvents() {
      return { click: this.clearCache };
    }
  },
  methods: {
    clearCache() {
      axios.post('/api/clearCache')
        .then(response => {
          document.webapp_messages.openAlert({ title: "Cache zurückgesetzt", message: "Der Cache wurde zurückgesetzt." });
        })
        .catch(error => {
          document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Zurücksetzen des Cache ist ein Fehler aufgetreten.", type: "error" });
        });
    }
  }
}
</script>