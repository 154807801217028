//Base System

export default [
  { 
    path: '/', 
    name: 'home', 
    component: require('../pages/Home.vue').default, 
    meta: { requiresAuth: false }
  }, { 
    path: '/admin/zahnarztpraxen', 
    name: 'admin_zahnarztpraxen', 
    component: require('../pages/Zahnarztsystem/ZahnarztPraxen.vue').default, 
    meta: { requiresAuth: true }
  }, { 
    path: '/admin/zahnarztpraxen/:id', 
    name: 'admin_zahnarztpraxen_single', 
    component: require('../pages/Zahnarztsystem/ZahnarztPraxis.vue').default, props:true,
    meta: { requiresAuth: true }
  }, {
    path: '/admin/jahresplan', 
    name: 'admin_jahresplan', 
    component: require('../pages/Zahnarztsystem/Jahresplan.vue').default, 
    meta: { requiresAuth: true, bodyClass: 'route_jahresplan' }
  }, {
    path: '/admin/mailsystem', 
    name: 'admin_mailsystem', 
    component: require('../pages/Zahnarztsystem/MailList.vue').default, 
    meta: { requiresAuth: true }
  }, {
    path: '/admin/mailsystem/:id', 
    name: 'admin_mailsystem_single', 
    component: require('../pages/Zahnarztsystem/Mail.vue').default, props:true,
    meta: { requiresAuth: true }
  }, {
    path: '/admin/boardsettings', 
    name: 'admin_boardsettings', 
    component: require('../pages/Zahnarztsystem/Boardsettings.vue').default, props:true,
    meta: { requiresAuth: true }
  }, { 
    path: '/preview', 
    name: 'preview', 
    component: require('../pages/Zahnarztsystem/Preview.vue').default, props:true,
    meta: { requiresAuth: false, bodyClass: 'route_preview' }
  }, { 
    path: '/impressum', 
    name: 'legal_notice', 
    component: require('../pages/LegalNotice.vue').default, props:true,
    meta: { requiresAuth: false }
  }, { 
    path: '/datenschutz', 
    name: 'privacy_police', 
    component: require('../pages/PrivacyPolice.vue').default, props:true,
    meta: { requiresAuth: false }
//  }, { 
//    path: '/zahnaerzte', 
//    name: 'simpleDentistListing', 
//    component: require('../pages/Zahnarztsystem/SimpleZahnaerzte.vue').default, props:true,
//    meta: { requiresAuth: false }

  }, { 
    path: '/dologout', 
    name: 'logout', 
    component: require('../pages/base/Logout.vue').default, 
    meta: { requiresAuth: false, }
  }, { 
    path: '/profile', 
    name: 'profile', 
    component: require('../pages/base/Profile.vue').default, 
    meta: { requiresAuth: true }
  }, { 
    path: '/createuser', 
    name: 'createuser', 
    component: require('../pages/base/CreateUser.vue').default, 
    meta: { requiresAuth: true }
  }, {
    path: '/base/login', 
    name: 'login', 
    component: require('../pages/base/Login.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/register', 
    name: 'register', 
    component: require('../pages/base/Register.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/forgot-password', 
    name: 'forgot-password', 
    component: require('../pages/base/ForgotPassword.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/reset-password/:token', 
    name: 'reset-password', 
    component: require('../pages/base/ResetPassword.vue').default, props: true,
    meta: { requiresAuth: false, resetAuth: true }
  },
  //TODO: 'base/user/confirm-password'
  //TODO: 'base/user/confirmed-password-status'

  //TODO: 'base/email/verify'

  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/About.vue')
    meta: { requiresAuth: true }
  }
  //*/
  { path: '*', component: require('../pages/404.vue').default, meta: { bodyClass: 'page404' }, }
];
