<template>
  <v-form @submit.prevent="handleDay()" class="mt-5">
    <strong>Einen Tag hinzufügen</strong>
    <v-text-field dense outlined v-model="value" clearable @click:clear="value=''" hide-details>
      <template v-slot:prepend-inner>
        <v-icon v-if="!isValidNewEntry.date && value.length<=2" left>mdi-chevron-right-circle</v-icon>
        <v-icon v-if="!isValidNewEntry.date && !isValidNewEntry.result && value.length>2" color="error" left>mdi-close-circle</v-icon>
        <v-icon v-if="isValidNewEntry.date && isValidNewEntry.result" color="success" left>mdi-check-circle</v-icon>
        <v-icon v-if="isValidNewEntry.date && !isValidNewEntry.result" color="warning" left>mdi-arrow-right-bold-circle</v-icon>
      </template>
    </v-text-field>
    <v-slide-y-transition>
      <div class="d-inline-block ml-3" v-if="value && value.length>2">
        <div v-html="isValidNewEntry.message"></div>
        <div v-if="isValidNewEntry.dentist || isValidNewEntry.result">
          <v-btn @click="handleDay()">[Enter] drücken oder hier klicken um Tag zuzuweisen.</v-btn>
        </div>
      </div>
    </v-slide-y-transition>
  </v-form>
</template>

<script>
import { parse } from 'date-fns';
export default {
  props:{
    year: { required:true, type:Number },
    daysOfYear: { required:true, type:Array },
    ignoreDentistsById: { required:false, type:Array, default:[] },
  },
  data: () => ({
    value: '', 
    regexNewEntry: /^(\d\d?)\.(\d\d?)\.?$/,
  }),
  computed: {

    isValidNewEntry() { 
      let result = { result:false, message:'', date:null, dentist:null, clearInput: this.clearInput };
      if(!this.value) return result;
      //check if long enough
      if(this.value.length<3) {
        result.message = 'Eingabe zu kurz';
        return result;
      }
      //check if correct syntax
      let m = this.regexNewEntry.exec(this.value);
      if(m === null) {
        result.message = 'Das Format stimmt nicht.';
        return result;
      }

      //check if real date
      let dateString = parseInt(m[1])+'-'+parseInt(m[2]);
      let testDate = parse(dateString, 'd-M', new Date(this.year,1,1));
      if(isNaN(testDate.getTime())) {
        result.message = 'Diese Datum gibt es nicht.';
        return result;
      }
      result.date = testDate;
      //check if alread used
      if(!this.daysOfYear) {
        result.message = 'Daten des Jahres nicht vorhanden.';
        return result;
      }
      let flatTestDate = this.$helper.format(testDate,'yyyy-MM-dd');
      let assignedDays = this.daysOfYear.find(elem => elem.date===flatTestDate && elem.dentist_id!==null);
      if(assignedDays && !this.ignoreDentistsById.includes( assignedDays.dentist.id )) {
        result.dentist = assignedDays.dentist;
        result.message = this.$helper.format(testDate,"EEEE 'der' dd.MM.yyyy")+' ist schon an <strong>'+result.dentist.gender+' '+result.dentist.title+' '+result.dentist.firstname+' '+result.dentist.lastname+'</strong> vergeben.';
        return result;
      }

      result.result = true;

      if(assignedDays && this.ignoreDentistsById.includes(assignedDays.dentist.id)) {
        result.message = this.$helper.format(testDate,"EEEE 'der' dd.MM.yyyy")+' ist bereits <strong>diesem Zahnarzt</strong> zugewiesen.';
      } else {
        result.message = this.$helper.format(testDate,"EEEE 'der' dd.MM.yyyy")+' ist noch keinem Zahnarzt zugewiesen.';
      }
      return result;
    }
  },
  methods: {
    handleDay() {
      this.$emit('complete', this.isValidNewEntry);
    },
    clearInput() {
      this.value = '';
    },
  }
}
</script>