<template>
  <span class="DayType" :class="cssClass"><span class="inner">{{output}}</span></span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props:{
    type:{ required: true },
    display: { required: false, type:String, default:'short' }
  },
  computed:{
    ...mapGetters({
      types : 'webapp/dayTypes',
    }),
    typeObject() {
      if(!this.types || !this.type) return null;
      return this.types.find(elem => elem.value === this.type);
    },
    output() {
      return (this.typeObject) ? this.typeObject[this.display] : null;
    },
    cssClass() {
      let result = {};
      if(this.typeObject) result['type-'+ this.typeObject.value] = true;
      return result;
    }
  }
}
</script>