<template>
  <v-chip class="DentistState" :class="innerState" small label :color="innerState==='active' ? 'green' : 'red'" dark>
    <span v-if="innerState==='active'">Aktiv</span>
    <span v-if="innerState==='inactive'">Inaktiv</span>
  </v-chip>
</template>

<script>
export default {
  props:{
    dentist:{ required: true, type: Object },
  },
  computed: {
    innerState() {
      if(this.dentist && this.dentist.state) return this.dentist.state;
      return '';
    }
  }
}
</script>