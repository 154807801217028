<template>
  <v-form @submit.prevent="submit">
    <p>
      Haben Sie Ihr Passwort vergessen? Kein Problem. Teilen Sie uns einfach Ihre E-Mail-Adresse mit, und wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts, mit dem Sie ein neues Passwort wählen können.
    </p>
    <v-text-field filled v-model="form.email" label="E-Mail" :error-messages="errors.email"></v-text-field>
    
    <div class="d-flex d-wrap">
      <v-btn @click="$router.push({name:'login'})" class="mt-1" depressed>Zum Login</v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-1" depressed color="primary">Passwort zurücksetzen</v-btn>
    </div>

    <v-alert class="mt-3" dense text type="success" v-if="message">{{message}}</v-alert> 
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  data: () => ({
    form:{
      email:"",
    },
    errors:[],
    message:null,
  }),
  methods:{
    submit() {
      let that = this;
      that.message = null;
      axios.get('/sanctum/csrf-cookie').then(response => {//first get the csrf-cookie (will be added in any further requests)
        axios.post('/forgot-password', this.form)
          .then(response => {
            that.message = response.data.message;
            that.$emit('complete', { message:that.message });
          })
          .catch(error => {
            console.log('error',error.response);
            that.errors = error.response.data.errors
          }); // credentials didn't match
      });
    },
  }
}
</script>