<template>
  <div id="app">
    <v-app>
      <v-main>
        <app-navigation class="d-print-none"></app-navigation>
        <v-container class="mainContainer">
          <OverlayLoading :show="!authRequestComplete" color="primary">loading</OverlayLoading>
          <router-view v-if="authRequestComplete"></router-view>
        </v-container>
        <app-footer class="d-print-none"></app-footer>
        <MessageConfirm entrypoint="webapp_messages"></MessageConfirm>
        <MessageAlert entrypoint="webapp_messages"></MessageAlert>
        <MessagePrompt entrypoint="webapp_messages"></MessagePrompt>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import appNavigation from '@/components/AppNavigation.vue';
import appFooter from '@/components/AppFooter.vue';
import MessageConfirm from '@/components/MessageConfirm.vue';
import MessageAlert from '@/components/MessageAlert.vue';
import MessagePrompt from '@/components/MessagePrompt.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';

export default {
  components:{
    appNavigation,
    appFooter,
    MessageConfirm,
    MessageAlert,
    MessagePrompt,
    OverlayLoading
  },
  computed: {
    authRequestComplete() {
      return this.$store.getters['webapp/basedata']!==null;
    }
  },
  mounted() {
    this.$store.dispatch('webapp/basedata');
    this.$helper.addHeaderIcon();
  }
}
</script>