<template>
  <div class="adminWrap">
    <div class="mb-5">
      <ZahnarztPraxen></ZahnarztPraxen>
    </div>
  </div>
</template>

<script>
import ZahnarztPraxen from './../../components/Zahnarztsystem/Praxis/ZahnarztPraxen.vue'
export default {
  components:{ ZahnarztPraxen },
  props:{}
}
</script>
