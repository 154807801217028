<template>
  <div class="MailPageSingle page">
    <div class="mb-5">
      <Mail :id="parseInt(id)"></Mail>
    </div>
  </div>
</template>

<script>
import Mail from '../../components/Zahnarztsystem/MailSystem/Mail.vue';
export default {
  components:{ Mail },
  props:{
    id:{ required:true }
  }
}
</script>
