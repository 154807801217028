<template>
  <form @submit.prevent="submit">
    <v-row>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.gender" label="Anrede" :error-messages="errors.gender"></v-text-field>
      </div>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.title" label="Titel" :error-messages="errors.title"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.firstname" label="Vorname" :error-messages="errors.firstname"></v-text-field>
      </div>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.lastname" label="Nachname" :error-messages="errors.lastname"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-12">
        <v-text-field filled v-model="form.company" label="Praxisname" :error-messages="errors.company"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-12">
        <v-text-field filled v-model="form.company_address" label="Adresse der Praxis" :error-messages="errors.company_address"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-4">
        <v-text-field filled v-model="form.company_postcode" label="PLZ" :error-messages="errors.company_postcode"></v-text-field>
      </div>
      <div class="col-sm-8">
        <v-text-field filled v-model="form.company_city" label="Stadt" :error-messages="errors.company_city"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.company_mail" label="E-Mail der Praxis" :error-messages="errors.company_mail"></v-text-field>
      </div>
      <div class="col-sm-6">
        <v-text-field filled v-model="form.company_web" label="Website der Praxis" :error-messages="errors.company_web"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-4">
        <v-text-field filled v-model="form.company_phone1" label="Telefon 1" :error-messages="errors.company_phone1"></v-text-field>
      </div>
      <div class="col-sm-4">
        <v-text-field filled v-model="form.company_phone2" label="Telefon 2" :error-messages="errors.company_phone2"></v-text-field>
      </div>
      <div class="col-sm-4">
        <v-text-field filled v-model="form.company_phone3" label="Telefon 3" :error-messages="errors.company_phone3"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-12">
        <v-text-field filled v-model="form.plan_mail" label="An welche E-Mail wird der Plan geschickt?" :error-messages="errors.plan_mail"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-12">
        <v-switch v-model="form.state" true-value="active" false-value="inactive" :error-messages="errors.state">
          <template v-slot:label>
            <span v-if="form.state==='active'">AKTIV: der Eintrag wird im Vergabe-System vorausgewählt.</span>
            <span v-if="form.state==='inactive'">INAKTIV: der Eintrag wird im Vergabe-System NICHT vorausgewählt.</span>
          </template>
        </v-switch>
      </div>
    </v-row>

    <v-alert v-if="failError" class="mt-1 mb-1" type="error">{{failError}}</v-alert>
    
    <p class="text-right">
      <v-btn color="primary" :loading="loading" depressed type="submit">Übernehmen</v-btn>
    </p>

  </form>
</template>

<script>
import { listDentist, createDentist, updateDentist } from '../api';
export default {
  props:{
    
    data : {
      required: false,
      type: Object,
    }
  },
  data:() => ({
    loading:false,
    form:{
      id:null,
      gender: '',
      title: '',
      firstname: '',
      lastname: '',
      plan_mail: '',
      company: '',
      company_address: '',
      company_postcode: '',
      company_city: '',
      company_mail: '',
      company_phone1: '',
      company_phone2: '',
      company_phone3: '',
      company_web: '',
      state: 'active',
    },
    errors: [],
    failError: '',
    stateItems:[
      { value: 'active', text : 'Aktiv' },
      { value: 'inactive', text : 'Inaktiv' },
    ],
  }),
  mounted() {
    this.loadItems();
    if(this.data) {
      this.form = JSON.parse(JSON.stringify( this.data ));
    }
  },
  methods: {
    reset() {
      this.form = {
        id:null,
        kurz: '',
        bezeichnung: '',
        ort: '',
      }
      this.errors = [];
    },
    loadItems() {
      if(this.loading) return;
      this.loading = true;

      listDentist()
        .then(response => {
          this.loading = false;
          this.items = response.data;
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Laden der Zahnarztpraxen ist ein Fehler aufgetreten.", type:"error" })
        })
    },
    submit() {
      if(this.loading) return;
      this.loading = true;
      
      this.errors = [];
      this.failError = '';
      let request = null;
      if(this.form.id===null) {
        request = createDentist(this.form);
      } else {
        request = updateDentist(this.form);
      }
      request
        .then(response => {
          this.loading = false;
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        })
    }
  }
}
</script>