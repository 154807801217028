<template>
  <span class="DentistInYear">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" small label class="grey lighten-3" v-if="isInYear"><v-icon>mdi-calendar-multiselect</v-icon></v-chip> 
        <v-chip v-bind="attrs" v-on="on" small label color="grey lighten-3" v-else><v-icon>mdi-minus</v-icon></v-chip> 
      </template>
      <div v-if="isInYear">Dem Zahnarzt wurde im Jahr {{year}} Tage zugewiesen.</div>
      <div v-else>Der Zahnarzt wurde im Jahr {{year}} NICHT zugewiesen.</div>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props:{
    year: { required: true, type: Number },
    days: { required: true, type: Array },
    dentist: { required: true, type: Object },
  },
  computed:{
    isInYear() {
      return !!this.days.find(elem => elem.dentist_id===this.dentist.id);
    }
  }
}
</script>