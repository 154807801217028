<template>
  <a :href="link" :target="target" :rel="rel">
    <v-icon left v-if="iconBefore">{{iconBefore}}</v-icon>{{text}}<v-icon right v-if="iconAfter">{{iconAfter}}</v-icon>
  </a>
</template>

<script>
export default {
  props:{
    number:{ required: true, type: String },
    target: { required:false, type: String, default:'_blank' },
    rel: { required:false, type: String, default:'external nofollow noopener noreferrer' },
    iconBefore: { required:false, type: [String, Boolean], default:false },
    iconAfter: { required:false, type: [String, Boolean], default:false },
  },
  computed: {
    link() {
      let tmp = this.number.toLocaleLowerCase();
      tmp = tmp.replace('(','');
      tmp = tmp.replace(')','');
      tmp = tmp.replace('+','0');
      tmp = tmp.replace('-','');
      tmp = tmp.replace(' ','');
      return 'tel:'+tmp;
    },
    text() {
      let tmp = this.number.toLocaleLowerCase();
      return tmp;
    }
  }
}
</script>