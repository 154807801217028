<template>
  <div class="home">
    <AppDashboard />
  </div>
</template>

<script>
// @ is an alias to /src
import AppDashboard from './../components/AppDashboard.vue'

export default {
  components: {
    AppDashboard
  },
}
</script>
