<!--
HOW TO USE
add MessagePrompt once anywhere inside your vuetify app. 
On a place where it always get loaded, i.e. before the closing "v-app"
<MessagePrompt entrypoint="my_entrypoint"></MessagePrompt>

Now you can use it anywhere in your code:
### promise based
    document.my_entrypoint.openPrompt({ message:"Thats the text" }).then(() => {
      console.log('Prompt closed');
    });

OR
### syncron

    async test() {
      let result = await document.my_entrypoint.openPrompt({ title: "Thats the title", message:"thats the text" });
      console.log('Prompt closed',result);
    }

### Parameters
title (optional)
message
btn_accept (optional) label for accept button
btn_dismiss (optional) label fro dismiss button
type (optional) a class variant added to the dialog
-->
<template>
  <v-dialog v-model="dialog" :content-class="dialogClass" @keydown="handleKeys" persistent :max-width="max_width">
    <v-card>
      <v-card-title class="d-block text-center pa-3 pb-0" v-if="title">{{title}}</v-card-title>
      <v-card-text class="pa-3">
        <div class="text-center" v-html="message"></div>
        <v-text-field v-model="value" outlined></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dismiss" tabindex="0">{{btn_dismiss}}</v-btn>
        <v-btn depressed color="primary" @click="accept" tabindex="0">{{btn_accept}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    entrypoint: {
      required:true,
      type:String
    }
  },
  data:() => ({
    dialog:false,
    message:"",
    title:"",
    btn_accept: "",
    btn_dismiss: "",
    type:"",
    value:"",
    max_width:400,
  }),
  computed: {
    dialogClass() {
      return 'messagePromptWrap messagePromptTheme-'+this.type;
    }
  },
  mounted() {
    this.reset();
    document.addEventListener(this.entrypoint+'_open_message_prompt', this.handleOpenEvent, { once:false /* handle listener only once*/ });
    if(document[this.entrypoint]===undefined) document[this.entrypoint] = {};
    document[this.entrypoint].openPrompt = this.publicFunction;
  },
  beforeDestroy() {
    document.removeEventListener(this.entrypoint+'_open_message_prompt', this.handleCloseEvent);
    document[this.entrypoint].openPrompt = undefined;
  },
  methods:{
    handleKeys(event) {
      switch(event.code) {
        case 'Enter': 
          event.stopPropagation();
          event.preventDefault();
          this.accept(); 
          return;
        case 'Escape': 
          event.stopPropagation();
          event.preventDefault();
          this.dismiss(); 
          return;
      }
    },
    reset() {
      this.dialog = false;
      this.message = "";
      this.title = "Hinweis";
      this.btn_accept = "OK";
      this.btn_dismiss = "Abbrechen";
      this.type = "default";
      this.value = "";
      this.max_width = 400;
    },
    handleOpenEvent(event) {
      this.dialog = true;
      this.message = event.detail.message;
      if(event.detail.title) this.title = event.detail.title;
      if(event.detail.btn_accept) this.btn_accept = event.detail.btn_accept;
      if(event.detail.btn_dismiss) this.btn_dismiss = event.detail.btn_dismiss;
      if(event.detail.type) this.type = event.detail.type;
      if(event.detail.value) this.value = event.detail.value;
      if(event.detail.max_width) this.max_width = event.detail.max_width;
    },
    dismiss() {
      /**
       * This function triggers an event on the dom-document.
       * How to listen to:
          document.addEventListener(this.entrypoint+'_message_prompt_close', function(e) {
              console.log(this.entrypoint+'_message_prompt_close', e.detail); // Prints "Example of an event"
          });
      */
      var event = new CustomEvent(this.entrypoint+'_message_prompt_close', { detail: null });
      document.dispatchEvent(event);
      this.reset();
    },
    accept() {
      console.log('accept',this.value);
      var event = new CustomEvent(this.entrypoint+'_message_prompt_close', { detail: this.value });
      document.dispatchEvent(event);
      this.reset();
    },


    /**
     * This function will be available via document.<your-entrypoint>.openPrompt
     */
    publicFunction(data) {
      //send open event to MessagePrompt-component 
      document.dispatchEvent(
        new CustomEvent(this.entrypoint+'_open_message_prompt', { detail: data })
      );

      return new Promise((resolve) => {//return promise so the caller can wait for it
        //wait for close event from MessagePrompt-component
        document.addEventListener(this.entrypoint+'_message_prompt_close', (data) => {
          resolve(data.detail);
        }, { once: true /* handle listener only once*/ });
      });
    }
  }
}
</script>