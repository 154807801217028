<template>
  <div>
    <v-card>
      <v-card-text>
        <div v-if="loading">
          <p class="text-center">Daten werden geladen</p>
          <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
        </div>
        <div v-if="!loading">
          <Today :days="days" :dentists="dentists" hideDentistWeb hideDentistMail></Today>
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="isBoxVisible" class="mt-5 messageBox">
      <v-card-text v-html="settings.messageBox.trim()"></v-card-text>
    </v-card>
    
    <v-card class="mt-5">
      <v-card-text>
        <h2>Wann gibt es welchen Bereitschaftsdienst?</h2>
        <h3 class="mt-3">Wochentags</h3>
        <p>
          Rufbereitschaft: von 20:00 bis 07:00 Uhr (des Folgetages)
        </p>
        <h3>Wochenende, Feiertag, Brückentage</h3>
        <p>
          Rufbereitschaft: von 7:00 Uhr bis 07:00 Uhr (des Folgetages)<br />
          Sprechzeiten von 10:00- 12:00 Uhr und 18:00- 19:00 Uhr, ansonsten Rufbereitschaft 
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { nextServices } from './../api';
import { add } from 'date-fns';
import { mapGetters } from 'vuex';
import Today from './Today.vue';
export default {
  components:{ Today },
  mounted() { this.setup(); },
  data:() =>({
    days:null,
    dentists:null,
    settings:null,
    loading:true,
  }),
  computed: {
    ...mapGetters({
      dayTypeItems : 'webapp/dayTypes',
    }),
    isBoxVisible() {
      return this.settings && this.settings.showMessageBox && this.settings.messageBox && this.settings.messageBox.trim().length>0;
    }
  },
  methods: {
    setup() {
      nextServices()
        .then(response => {
          this.days = response.data.days;
          this.dentists = response.data.dentists;
          this.settings = response.data.settings;

          this.days.forEach(elem => {
            this.optimizeDay(elem);
          });
          this.loading = false;
        })
        .catch( () => {
          this.loading = false;
        });
    },
    optimizeDay(day) {
      day.dentist = this.dentists.find(d => d.id === day.dentist_id);
      day.typeItem = this.dayTypeItems.find(i => i.value === day.type);
      day.dateObject = this.$helper.parseDate(day.date, 'yyyy-MM-dd');
      day.dateObject_start = add(day.dateObject, { minutes: day.typeItem.startHour * 60 });
      day.dateObject_end = add(day.dateObject, { minutes: day.typeItem.endHour * 60 });
    }
  }
}
</script>