<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" >
      <v-tooltip bottom content-class="d-print-none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="Object.assign(on, slotEvents)" v-bind="attrs">E-Mail Link öffnen</v-btn>
        </template>
        <div>
          Link öffnen, so wie ihn der Zahnarzt sieht.
        </div>
      </v-tooltip>
    </slot>

    <v-dialog v-model="openLink.modal" max-width="650">
      <v-card>
        <v-btn class="modalClose" @click="openLink.modal=false">&times;</v-btn>
        <v-card-title>Zahnarzt wählen für den der Link geöffnet werden soll.</v-card-title>
        <v-card-text class="pt-4" v-if="openLink.modal">
          <template v-if="dentists">
            <DentistAutocomplete v-model="openLink.dentist" :dentists="dentists" label="Zahnarzt auswählen" hide-details outlined return-object></DentistAutocomplete>
            <v-btn color="primary" class="mt-3" v-if="!openLink.dentist" disabled>jetzt öffnen</v-btn>
            <v-btn color="primary" class="mt-3" v-else :href="'/preview?id='+openLink.mailIdentifier+'&dentist='+openLink.dentist.identifier" target="_blank">jetzt öffnen</v-btn>

            <template v-if="randomDentist">
              <v-divider class="my-4"></v-divider>
              <h3>Alternativ hier ein zufälliger Zahnarzt </h3>
              <p>({{$helper.getDentistFullName(randomDentist)}})</p>
              <v-btn color="primary" :href="'/preview?id='+openLink.mailIdentifier+'&dentist='+randomDentist.identifier" target="_blank">zufälligen Zahnarzt öffnen</v-btn>
            </template>
          </template>
          <template v-else>
            <p>Bisher wurden die Zahnärzte noch nicht geladen.</p>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import DentistAutocomplete from './../DentistAutocomplete.vue';
export default {
  components: { DentistAutocomplete },
  props: {
    mailIdentifier: { required:true, type: String },
    dentists: { required:true, type: Array },
    randomDentist: { required:false, type: Object },
  },
  data: () =>({
    openLink: {
      modal:false,
      mailIdentifier: null,
      dentist: null,
    }
  }),
  computed:{
    slotEvents() {
      return { click: this.openLikeDentist }
    }
  },
  methods: {
    openLikeDentist() {
      this.openLink.modal = true;
      this.openLink.mailIdentifier = this.mailIdentifier;
    }
  }

}
</script>