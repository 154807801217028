var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"padless":"","fixed":""}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":"","color":"grey lighten-2"}},[_c('v-card-text',{staticClass:"py-2 white--text text-center"},[_c('router-link',{attrs:{"to":{name:'home'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('v-btn',{attrs:{"text":"","href":href}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-home")])],1)]}}])}),_c('router-link',{attrs:{"to":{name:'legal_notice'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('v-btn',{attrs:{"text":"","href":href}},[_vm._v("Impressum")])]}}])}),_c('router-link',{attrs:{"to":{name:'privacy_police'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('v-btn',{attrs:{"text":"","href":href}},[_vm._v("Datenschutzerklärung")])]}}])}),_c('app-version'),_c('router-link',{attrs:{"to":{name:'login'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('v-btn',{attrs:{"text":"","href":href}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-circle")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }