var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-white"},[(_vm.isLoggedIn)?_c('v-toolbar',[_c('v-toolbar-items',[_c('router-link',{staticClass:"v-toolbar__title v-btn v-btn--flat text-none px-2",attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":require("./../assets/images/logo.png"),"alt":"Logo","height":"40"}})])],1),_c('v-spacer'),(_vm.isLoggedIn)?_c('v-toolbar-items',[_c('router-link',{attrs:{"to":{name:'admin_jahresplan'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-btn',{attrs:{"href":href,"color":(isActive || isExactActive) ? 'primary' : 'default',"depressed":""},on:{"click":navigate}},[_vm._v("Jahresplan")])]}}],null,false,3066945593)}),_c('router-link',{attrs:{"to":{name:'admin_zahnarztpraxen'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-btn',{attrs:{"href":href,"color":(isActive || isExactActive) ? 'primary' : 'default',"depressed":""},on:{"click":navigate}},[_vm._v("Zahnärzte")])]}}],null,false,3935640141)}),_c('router-link',{attrs:{"to":{name:'admin_mailsystem'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-btn',{attrs:{"href":href,"color":(isActive || isExactActive) ? 'primary' : 'default',"depressed":""},on:{"click":navigate}},[_vm._v("Mail-System")])]}}],null,false,684497132)}),_c('router-link',{attrs:{"to":{name:'admin_boardsettings'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-btn',{attrs:{"href":href,"color":(isActive || isExactActive) ? 'primary' : 'default',"depressed":""},on:{"click":navigate}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}],null,false,1144419900)})],1):_vm._e(),(_vm.isLoggedIn)?_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,false,3853626615)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',{staticClass:"pr-5"},[_vm._v("Profil")])],1),_c('Logout',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-title',{staticClass:"pr-5"},[_vm._v("Logout")])],1)]}}],null,false,81670804)})],1)],1)],1):_c('v-toolbar-items',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }