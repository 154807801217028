<template>
  <form @submit.prevent="submit">
    <h2 class="mb-5" v-if="dateObject">{{ $helper.format(dateObject, 'EEEE dd. MMMM yyyy') }}</h2>
    <v-row>
      <div class="col-sm-12">
        <v-slide-y-transition>
          <v-btn x-small color="primary" class="mb-2" v-if="form.dentist_id" @click="$router.push({ name:'admin_zahnarztpraxen_single', params: { id: form.dentist_id} })">Zahnarztseite öffnen</v-btn>
        </v-slide-y-transition>
        <DentistAutocomplete label="Zugewiesener Arzt"
          v-model="form.dentist_id"
          :dentists="items"
        ></DentistAutocomplete>
      </div>
    </v-row>
    <v-row>
      <div class="col-sm-12">
        <v-select filled label="Welcher Dienst an dem Tag angeboten"
          v-model="form.type"
          :items="dayTypeItems"
          item-key="value"
          :error-messages="errors.state"
        ></v-select>
      </div>
    </v-row>
    <!--
    <v-row>
      <div class="col-sm-12">
        <v-select filled label="Welchen Status hat der Tag"
          :items="dayStateItems"
          v-model="form.state"
          item-key="value"
          :error-messages="errors.state"
        ></v-select>
      </div>
    </v-row>
    -->
    
    <p class="text-right">
      <v-btn color="primary" :loading="loading" depressed type="submit">Übernehmen</v-btn>
    </p>

    <v-alert v-if="failError" class="mt-3 mb-0" type="error">{{failError}}</v-alert>
  </form>
</template>

<script>
import { listDentist, createDay, updateDay } from '../api';
import { mapGetters } from 'vuex';
import { parse } from 'date-fns';
import DentistAutocomplete from './../DentistAutocomplete.vue'
export default {
  components: { DentistAutocomplete },
  props:{
    data : { required: false, type: Object }
  },
  data:() => ({
    loading:false,
    form:{
      id:null,
      date: "",
      type: "normal",
      dentist_id: null,
      state: "open",
      meta: null
    },
    errors: [],
    failError: "",

    items: [],
  }),
  computed:{
    ...mapGetters({
      dayTypeItems : 'webapp/dayTypes',
      dayStateItems : 'webapp/dayStates',
    }),
    dateObject() {
      if(!this.form) return null;
      if(this.form.dateObject && this.form.dateObject instanceof Date) return this.form.dateObject;
      if(this.form.date) return parse(this.form.date, 'yyyy-MM-dd', new Date(2000, 1, 1));
      return null;
    }
  },
  mounted() {
    this.loadItems();
    if(this.data) {
      this.form = JSON.parse(JSON.stringify( this.data ));
    }
  },
  methods: {
    filterDentists(item, queryText, itemText) {
      let search = queryText.toLocaleLowerCase();
      return item.firstname.toLocaleLowerCase().indexOf(search) > -1
        || item.lastname.toLocaleLowerCase().indexOf(search) > -1
      ;
    },
    reset() {
      this.form = {
        id:null,
        date: "",
        type: "normal",
        dentist_id: null,
        state: "open",
        meta: null
      }
      this.errors = [];
    },
    loadItems() {
      if(this.loading) return;
      this.loading = true;

      listDentist()
        .then(response => {
          this.loading = false;
          this.items = response.data;
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Laden der Zahnärzte ist ein Fehler aufgetreten.", type:"error" })
        });
    },
    submit() {
      if(this.loading) return;
      this.loading = true;
      
      this.errors = [];
      this.failError = "";

      let request = null
      if(this.form.id===null) {
        request = createDay(this.form)
      } else {
        request = updateDay(this.form)
      }
      request
        .then(response => {
          this.loading = false;
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        });
    }
  }
}
</script>