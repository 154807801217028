<template>
  <v-form @submit.prevent="submit">
    <v-text-field filled v-model="form.email" label="E-Mail" :error-messages="errors.email"></v-text-field>
    <v-text-field filled type="password" v-model="form.password" label="Passwort" :error-messages="errors.password"></v-text-field>
    <v-text-field filled type="password" v-model="form.password_confirmation" label="Passwort Bestätigung" :error-messages="errors.password_confirmation"></v-text-field>
    
    <div class="d-flex flex-wrap">
      <v-btn @click="$router.push({name:'login'})" class="mt-1" depressed>zum Login</v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-1" depressed color="primary">neues Passwort übernehmen</v-btn>
    </div>

    <v-alert class="mt-3" dense text type="success" v-if="message">{{message}}</v-alert> 
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    token:{
      type:String,
      required:true,
    }
  },
  data: () => ({
    form:{
      email:"",
      password:"",
      password_confirmation:"",
    },
    errors:[],
    message:null,
  }),
  mounted() {
    if(this.$route.query.email!==undefined) {
      this.form.email = this.$route.query.email;
    }
  },
  methods:{
    submit() {
      let that = this;
      let data = {
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        token: this.token,
      };
      axios.get('/sanctum/csrf-cookie').then(response => {//first get the csrf-cookie (will be added in any further requests)
        axios.post('/reset-password', data)
          .then(response => {
            that.message = response.data.message;
            that.form.email = "";
            that.form.password = "";
            that.form.password_confirmation = "";
            that.errors = [];
            that.$emit('complete', { message:that.message });
          })
          .catch(error => {
            console.log('error',error.response);
            that.errors = error.response.data.errors
        });
      });
    },
  }
}
</script>