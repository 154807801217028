<template>
  <v-card class="text-center" flat>
    <v-card-text>
      <h3 class="mb-4">Seite nicht gefunden</h3>

      <div class="links">
        <router-link :to="'/'">Zur Startseite</router-link>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
