import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import VueBodyClass from 'vue-body-class'
import authGuard from './guards/auth';
import resetAuthGuard from './guards/reset-auth';

Vue.use(VueRouter);




// create router
const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV==='production' ? '/' : process.env.BASE_URL,
  routes
});


router.beforeEach(authGuard);
router.beforeEach(resetAuthGuard);

//setup vue body class
const vueBodyClassInstance = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClassInstance.guard(to, next) });

export default router