<template>
  <div class="mailsystemPage page">
    <div class="mb-5">
      <MailList></MailList>
    </div>
  </div>
</template>

<script>
import MailList from '../../components/Zahnarztsystem/MailSystem/MailList.vue';
export default {
  components:{ MailList },
  props:{
  }
}
</script>
