<template>
  <div class="SimpleToday">
    <div v-if="currentServiceDay">
      <div v-if="currentServiceDay.typeExact">
        <v-alert color="primary" dark class="text-center" width="100%">
          <h2 class="my-3">Aktueller Bereitschaftsdienst</h2>
        </v-alert>
      </div>
      <div v-else>
        <v-row>
          <v-alert color="primary" dark class="text-center" width="100%">
            <h2 class="my-3">Aktuell KEIN Bereitschaftsdienst</h2>
            <h3 class="my-3">Bitte wenden Sie sich an Ihren regulären Zahnarzt.</h3>
          </v-alert>
        </v-row>
        <h2 class="my-3">Nächster Bereitschaftsdienst</h2>
      </div>
      <div class="timeSlot">
        <span class="timeSlotInfo">Rufbereitschaft</span>
        <h3>
          von {{$helper.format(currentServiceDay.day.dateObject_start, "EEEEEEE, dd.MM.yyyy 'um' HH:mm")}} Uhr<br />
          bis {{$helper.format(currentServiceDay.day.dateObject_end, "EEEEEEE, dd.MM.yyyy 'um' HH:mm")}} Uhr
        </h3>
      </div>
      <div class="officeHours" v-if="currentServiceDay.day.type==='weekend' || currentServiceDay.day.type==='special'">Sprechzeiten von 10:00- 12:00 Uhr und 18:00- 19:00 Uhr, ansonsten Rufbereitschaft</div>
      <Dentist class="mt-5" :dentist="currentServiceDay.day.dentist" :hideWeb="hideDentistWeb" :hideMail="hideDentistMail"></Dentist>
      <p class="mt-5 mb-0">
        Bitte nehmen Sie <strong>vor dem Besuch einer Zahnarztpraxis</strong> telefonisch Kontakt auf!
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DayType from './../DayType.vue';
import Dentist from './Dentist.vue';
export default {
  components: { DayType, Dentist },
  props: {
    days: { required: true, type:Array },
    dentists: { required: true, type:Array },
    hideDentistWeb: { required: false, type:Boolean, default: false },
    hideDentistMail: { required: false, type:Boolean, default: false },
  },
  mounted() { this.setup(); },
  beforeDestroy() { this.tearDown(); },
  data: () =>({
    tabs:1,

    dayDialog: {
      modal:false,
      day:null
    },
    currentDayTime:null,
    currentDayTimeInterval:null,
  }),
  computed: {
    ...mapGetters({
      dayTypeItems : 'webapp/dayTypes',
    }),
    currentServiceDay() {
      if(!this.days || !this.currentDayTime) return null;
      let result = { 
        typeExact:true, 
        day:null,
        remaining: this.days.filter(elem => true) 
      };
      let exactMatch = result.remaining.find(elem =>
        this.currentDayTime.getTime() > elem.dateObject_start.getTime() &&
        this.currentDayTime.getTime() < elem.dateObject_end.getTime()
      );
      if(exactMatch) {
        result.day = exactMatch;
        result.remaining = result.remaining.filter(elem => elem.id!==exactMatch.id);//without exact day
      } else {
        result.typeExact = false;
        result.remaining = result.remaining.filter(elem => this.currentDayTime.getTime() < elem.dateObject_start.getTime());
        result.day = result.remaining.shift();
      }
      return result;
    }
  },
  methods:{
    dayClick(day) {
      if(!day || !day.content) return;
      this.dayDialog.modal = true;
      this.dayDialog.day = day;
    },
    tearDown() {
      clearInterval(this.currentDayTimeInterval);
    },
    setup() {
      //Test-Date
      /*
      this.currentDayTime = new Date();
      let differenceHours = 0;
      differenceHours+= 5;
      differenceHours+= -24*1;
      this.currentDayTime.setHours(differenceHours,0,0);
      /*/
      this.currentDayTime = new Date();
      this.currentDayTimeInterval = setInterval(() => {
        console.log('new current DayTime');
        this.currentDayTime = new Date();
      }, 1000*30);
      //*/
    },
  }
}
</script>