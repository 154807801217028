<template>
  <div class="hello">

    <div v-if="isLoggedIn" class="mb-10 pb-5">
      <v-card v-if="$store.getters['webapp/userCan']('edit users')" class="my-5" outlined style="border-color: var(--color-main);">
        <v-card-title>Sie sind erfolgreich eingeloggt</v-card-title>
        <v-card-text>
          <v-btn @click="$router.push({name:'admin_jahresplan'})" v-if="$store.getters['webapp/userCan']('edit users')" class="ml-1 my-1" depressed>Jahresplan</v-btn>
          <v-btn @click="$router.push({name:'admin_zahnarztpraxen'})" v-if="$store.getters['webapp/userCan']('edit users')" class="ml-1 my-1" depressed>Zahnärzte</v-btn>
          <v-btn @click="$router.push({name:'admin_mailsystem'})" v-if="$store.getters['webapp/userCan']('edit users')" class="ml-1 my-1" depressed>Mail-System</v-btn>
          
          <v-switch v-model="debugView" @change="updateDebugView" hide-details label="Debug-View anzeigen?"></v-switch>
        </v-card-text>
      </v-card>
    </div>

    <div>

      <v-row>
        <div class="col-md-6">
          <v-card>
            <v-img :src="require('./../assets/images/pexels-cedric-fauntleroy-4269694.jpg')" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="300px">
              <v-card-title>
                Willkommen
              </v-card-title>
            </v-img>
            <v-card-text>
              <router-link :to="{ name: 'home' }" class="v-toolbar__title v-btn v-btn--flat text-none px-2 mb-5">
                <img src="./../assets/images/logo.png" alt="Logo" height="80" /> 
              </router-link>
              <div style="font-size:1.1em;">

                <p>Willkommen bei der Notfallbetreuung Ihrer ZahnärztInnen im Landkreis Spree-Neiße.</p>

                <h3>Mit welchen Symptomen zur Notfallbehandlung?</h3>
                <p>Nicht bei jedem Zahnschmerz handelt es sich um einen Notfall, mit dem man sofort einen Zahnarzt aufsuchen muss. Als zahnärztlicher Notfall gelten in der Regel:</p>
                <ul class="chevronList">
                  <li>Unfallverletzungen im Bereich von Zahn, Mund und Kiefer</li>
                  <li>Nachblutungen nach zahnärztlichen oder chirurgischen Eingriffen</li>
                  <li>Fieberhafte Abszesse oder akute Entzündungen</li>
                </ul>
                <p>
                  Bei großflächigen Verletzungen im Gesicht bitte immer zuerst die Notaufnahme aufsuchen!
                </p>
                
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-md-6">
          <SimpleOutput></SimpleOutput>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import Login from './baseSystem/Login.vue';
import ClearCache from './administration/ClearCache.vue';
import ChangePassword from './baseSystem/ChangePassword.vue';
import SimpleOutput from './Zahnarztsystem/Simple/SimpleOutput.vue';
import { mapGetters } from 'vuex';
export default {
  components:{ Login, ClearCache, ChangePassword, SimpleOutput },
  props: {},
  data: () => ({
    updateUserPassword:false,
    debugView:false,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'webapp/isLoggedIn',
    }),
  },
  mounted() {
    this.debugView = 'true' === window.localStorage.getItem('webapp_debug_view');
  },
  methods:{
    updateDebugView(value) {
      window.localStorage.setItem('webapp_debug_view',value);
    }
  }
}
</script>