<template>
  <div class="zahnarztPraxisWrap page">
    <div class="mb-5">
      <ZahnarztPraxis :id="parseInt(id)"></ZahnarztPraxis>
    </div>
  </div>
</template>

<script>
import ZahnarztPraxis from './../../components/Zahnarztsystem/Praxis/ZahnarztPraxis.vue'
export default {
  components:{ ZahnarztPraxis },
  props:{
    id:{
      required:true
    }
  }
}
</script>
