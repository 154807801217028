var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zahnarztManagementWrap"},[_c('h1',[_vm._v("Zahnarztpraxen")]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredData,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.searchFilter,"loading-text":"Lade Daten ... Bitte warten Sie"},on:{"click:row":_vm.clickEntry},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('div',{staticClass:"d-flex flex-wrap dataTableTopBar"},[_c('v-btn',{staticClass:"mt-3",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.loadDentists}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.createItem()}}},[_vm._v("Zahnarztpraxis erstellen")]),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"text":""},on:{"click":function($event){return _vm.changeYear(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"disabled":"","label":""}},[_vm._v(_vm._s(_vm.year))]),_c('v-btn',{staticClass:"mt-3 mx-1",attrs:{"text":""},on:{"click":function($event){return _vm.changeYear(1)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-spacer'),_c('v-data-footer',{staticClass:"noBorder",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.getDentistFullName(item))+" ")]}},{key:"item.attachedDaysCount",fn:function(ref){
var item = ref.item;
return [_c('DiagramLinear',{attrs:{"days":_vm.days,"dentist":item.id,"year":_vm.year}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('DentistState',{attrs:{"dentist":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickEntry(item)}}},[_vm._v("Details")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.formularModal),callback:function ($$v) {_vm.formularModal=$$v},expression:"formularModal"}},[(_vm.formularItem===null)?_c('v-card',[_c('v-btn',{staticClass:"modalClose",on:{"click":function($event){_vm.formularModal=false}}},[_vm._v("×")]),_c('v-card-title',[_vm._v("Neue Praxis erstellen")]),(_vm.formularModal)?_c('v-card-text',[_c('ZahnarztPraxisFormular',{on:{"complete":_vm.formularComplete}})],1):_vm._e()],1):_c('v-card',[_c('v-btn',{staticClass:"modalClose",on:{"click":function($event){_vm.formularModal=false}}},[_vm._v("×")]),_c('v-card-title',[_vm._v("Praxis bearbeiten")]),(_vm.formularModal)?_c('v-card-text',[_c('ZahnarztPraxisFormular',{attrs:{"data":_vm.formularItem},on:{"complete":_vm.formularComplete}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }