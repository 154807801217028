<template>
  <section class="Monat">
    <header>
      <slot name="header" v-bind="{ month : month }">
        <span class="monthName">{{$helper.format(month,'MMMM')}}</span> <span class="monthYear">{{$helper.format(month,'yyyy')}}</span>
      </slot>
    </header>
    <ul>
      <li v-for="d in dateEntries" :key="d.day">
        <div class="day" :class="getCssClass(d)">
          <div class="dayLabel" @click="dayClick(d, 'label')">
            <div class="inner">
              <span class="weekDay">{{$helper.format(d.date, 'EEEEEE') }}</span>
              <span class="monthDay">{{d.day}}</span>
            </div>
          </div>
          <template v-if="d.content">
            <div class="dayContent" @click="dayClick(d, 'content')">
              <slot name="content" v-bind="{ data : d.content }">
                {{d.content[valueName]}}
              </slot>
            </div>
          </template>
          <template v-else>
            <div class="dayContent empty" @click="dayClick(d, 'content')"></div>
          </template>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import getDaysInMonth from 'date-fns/getDaysInMonth'
export default {
  components: {  },
  props:{
    month:{ required: true, type: Date },
    content: { required: false, type: Array },
    valueName: { required:false, type: String, default: 'text' },
  },
  computed: {
    dateEntries() {
      let daysInMonth = getDaysInMonth(this.month);
      let result = [];
      for(let i = 1; i<=daysInMonth; i++) {
        let current = new Date(this.month);
        current.setDate(i);
        let compareDate = this.$helper.format(current, 'yyyy-MM-dd');
        let newEntry = {
          day: i,
          date: current,
        };
        if(this.content) {
          let data = this.content.find(elem => elem.date === compareDate);
          if(data) newEntry.content = data;
        }
        result.push(newEntry);
      }

      return result;
    }
  },
  methods:{
    normalizeDay(date) {
      date.setHours(12,0,0,0);
    },
    dayClick(day, type) {
      if(type==='label') {
        this.$emit('labelClick',day);
        return;
      }
      this.$emit('dayClick',day);
    },
    getCssClass(day) {
      let result = {};
      if(day.content && typeof day.content.cssClass === 'string') {
        result[day.content.cssClass] = true;
      } else {
        result.default = true;
      }
      return result;
    }
  }
}
</script>