<template>
  <div class="MailList">
    <h1>Arzt-Mails</h1>
    
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :search="search"
      loading-text="Lade Daten ... Bitte warten Sie"
      @click:row="clickEntry"
    >

      <!-- top pagination -->
      <template v-slot:top="{ pagination, options, updateOptions }">
        <div class="d-flex flex-wrap dataTableTopBar">
          <v-btn class="mt-3" text @click="loadMails" :loading="loading"><v-icon>mdi-reload</v-icon></v-btn>
          <v-divider vertical></v-divider>
          <v-btn class="mt-3 ml-2" @click="createItem()" color="primary" depressed>neue Mail erstellen</v-btn>
          <v-spacer></v-spacer>
          <v-data-footer class="noBorder" :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
        </div>
      </template>

      <template v-slot:[`item.dentists`]="{ item }">
        {{ item.dentists.length }}
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <div v-if="item.state==='created'" class="text-center">
          <v-chip outlined small color="warning">erstellt</v-chip>
        </div>
        <div v-if="item.state==='send'" class="text-center">
          <v-chip small color="success">Mails versendet</v-chip>
        </div>
        <div style="line-height:1" class="text-center"><small>{{ $helper.format(item.relevant_date, 'dd.MM.yyyy HH:mm') }}</small></div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom content-class="d-print-none">
          <template v-slot:activator="{ on: tooltip_on, attrs: tooltip_attrs }">
            <span v-bind="tooltip_attrs" v-on="tooltip_on">
              <v-btn @click.stop="clickOnDuplicate(item)" ><v-icon>mdi-content-duplicate</v-icon></v-btn>
            </span>
          </template>
          Eintrag duplizieren
        </v-tooltip>

        <EmailLinkDialog :mailIdentifier="item.identifier" :dentists="dentists" :randomDentist="randomDentist">
          <template v-slot:activator="{ on, attrs }">
          
            <v-tooltip bottom content-class="d-print-none">
              <template v-slot:activator="{ on: tooltip_on, attrs: tooltip_attrs }">
                <span v-bind="tooltip_attrs" v-on="tooltip_on">
                  <v-btn class="ml-1" v-bind="attrs" @click.stop="on.click"><v-icon>mdi-share-outline</v-icon></v-btn>
                </span>
              </template>
              Link öffnen, so wie ihn der Zahnarzt sieht.
            </v-tooltip>
          </template>
        </EmailLinkDialog>
        <v-btn class="ml-1" depressed @click="clickEntry(item)">Details</v-btn>
      </template>

    </v-data-table>

    <v-toolbar flat>
      <v-spacer></v-spacer>
    </v-toolbar>
   
    <v-dialog v-model="formularModal" max-width="700">
      <v-card v-if="formularItem===null">
        <v-btn class="modalClose" @click="formularModal=false">&times;</v-btn>
        <v-card-title>Neuen Mailversand vorbereiten</v-card-title>
        <v-card-text v-if="formularModal">
          <p>Für welches Jahr soll eine E-Mail versendet werden.</p>
          <div>
            <v-btn class="mt-3 mx-1" text @click="changeYear(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn class="mt-3 mx-1" disabled label>{{year}}</v-btn>
            <v-btn class="mt-3 mx-1" text @click="changeYear(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </div>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="doCreateDentistMail" color="primary">Neuen Mail-Versand vorbereiten</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { listDentist, listDentistMail, createDentistMail, duplicateDentistMail } from './../api';
import { compareDesc } from 'date-fns'
import EmailLinkDialog from './EmailLinkDialog.vue';
export default {
  props:{},
  components: { EmailLinkDialog },
  mounted() {
    this.year = (new Date()).getFullYear();
    this.year++;

    this.loadMails();
    this.loadDentists();
  },
  data:() => ({
    loading: false,
    data: [],
    search: '',
    headers: [
      { value: 'year', text: 'Jahr' },
      { value: 'subject', text: 'Betreff' },
      { value: 'state', text: 'Status' },
      { value: 'dentists', text: 'zugewiesene Ärzte' },
      { value: 'actions', text: '', align:'right' }
    ],
    itemsPerPage: 10,

    formularModal: false,
    formularItem: null,

    days: [],
    year: 2000,

    dentists: [],
  }),
  computed:{
    filteredData() { return this.data; },
    randomDentist() {
      if(this.dentists.length===0) return null;
      return this.dentists[ Math.floor( Math.random() * this.dentists.length ) ];
    }
  },
  methods:{
    clickEntry(item) {
      this.$router.push({ name:'admin_mailsystem_single', params: { id: item.id} });
    },
    async clickOnDuplicate(data) {
      if(this.loading) return;
      let result = await document.webapp_messages.openConfirm({ title: "Duplizieren?", message:"Wollen Sie diesen Eintrag duplizieren?" });
      if(result) {
        await duplicateDentistMail(data.id);
        this.loadMails();
      }
    },
    formularComplete() {
      this.formularModal = false;
      this.formularItem = null;
      this.loadMails();
    },
    createItem() {
      this.formularModal = true;
      this.formularItem = null;
    },
    loadMails() {
      if(this.loading) return;
      this.loading = true;
      
      listDentistMail()
        .then(response => {
          this.loading = false;
          this.data = response.data;
          this.data.forEach(elem => {
            elem.dentists = JSON.parse(elem.dentists)
            elem.created_at_date = this.$helper.transformPhpDateString(elem.created_at);
            elem.updated_at_date = this.$helper.transformPhpDateString(elem.updated_at);
            elem.relevant_date = elem.created_at_date;
            if(elem.state==='send') elem.relevant_date = elem.updated_at_date;
          });
          
          this.data.sort((a,b) => compareDesc(a.relevant_date,b.relevant_date));
        })
        .catch(() => {
          this.loading = false;
        })
    },
    loadDentists() {
      listDentist()
        .then(response => {
          this.dentists = response.data;
        })
    },
    changeYear(direction) {
      this.year = this.year+direction;
    },
    doCreateDentistMail() {
      if(this.loading) return;
      this.loading = true;
      
      createDentistMail( { year: this.year, state: 'created' } )
        .then(response => {
          this.loading = false;
          this.$router.push({ name: 'admin_mailsystem_single', params: { id: response.data.id } })
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.failError = error.response.data.message;
        });
    },
    
  }
}
</script>