<template>
  <a class="LinkMaps" :href="link" :target="target" :rel="rel">
    <v-icon left v-if="iconBefore">{{iconBefore}}</v-icon>{{text}}<v-icon right v-if="iconAfter">{{iconAfter}}</v-icon>
  </a>
</template>

<script>
export default {
  props:{
    address:{ required: true, type: String },
    postcode: { required:true, type: String },
    city: { required:true, type: String },
    text: { required:false, type: String, default: 'Auf Google Maps anzeigen' },
    rel: { required:false, type: String, default:'external nofollow noopener noreferrer' },
    target: { required:false, type: String, default:'_blank' },
    iconBefore: { required:false, type: [String, Boolean], default:false },
    iconAfter: { required:false, type: [String, Boolean], default:false },
  },
  computed: {
    link() {
      return 'https://www.google.com/maps/search/?api=1&query='+encodeURI(this.address+', '+this.postcode+' '+this.city);
    }
  }
}
</script>