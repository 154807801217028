<template>
  <span>
    <slot name="activator" v-bind="{ on: slotEvents }" >
      <v-tooltip bottom content-class="d-print-none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="Object.assign(on, slotEvents)" v-bind="attrs"><v-icon>mdi-printer</v-icon></v-btn>
        </template>
        <div>
          Aktuelle Ansicht Drucken
        </div>
      </v-tooltip>
    </slot>

    <v-tooltip bottom content-class="d-print-none">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-on="Object.assign(on, {click: openHelperDialog})" v-bind="attrs"><v-icon>mdi-help</v-icon></v-btn>
      </template>
      <div>
        Technische Hilfe zum Drucken der Daten
      </div>
    </v-tooltip>

    <v-dialog v-model="helperDialog" max-width="600" class="d-print-none">
      <v-card>
        <v-btn class="modalClose" @click="helperDialog=false">&times;</v-btn>
        <v-card-text class="pt-4" v-if="helperDialog">
          <h2 class="mb-5">So holen Sie das Beste aus dem Druck heraus</h2>
          <p>Die Tabelle wird mit der Druckfunktion ihres Browser gedruckt.</p>
          <p>Nehmen Sie folgende Einstellungen im Druck-Dialog vor, um das optimale Ergebnis zu erhalten:</p>
          <ul>
            <li><a @click="createPdf">öffnen Sie den Druck-Dialog</a></li>
            <li>wählen sie bei Drucker: "<strong>Als PDF drucken</strong>"</li>
            <li>klicken Sie auf "<strong>weitere Einstellungen</strong>"</li>
            <li>Skalieren Sie auf <strong>Benutzerdefiniert - 70</strong></li>
            <li><strong>deaktiveren Sie "Kopf- und Fusszeile"</strong></li>
            <li><strong>aktivieren Sie "Hintergrundgrafiken"</strong></li>
          </ul>
          <v-divider class="my-5"></v-divider>
          <p>
            Wenn Sie <strong>6 oder 12 Monate</strong> auf ein Blatt haben wollen, empfehlen Wir Ihnen <strong>Querformat</strong> auszuwählen. 
            Die Anzahl der Monate müssen Sie einstellen, bevor Sie den Druck-Dialog öffnen.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
export default {
  data:() =>({
    helperDialog:false
  }),
  computed: {
    slotEvents() {
      return { click: this.createPdf };
    }
  },
  methods: {
    openHelperDialog() {
      this.helperDialog = true;
    },
    createPdf() {
      if(this.helperDialog) {
        this.helperDialog = false;
        setTimeout(() => {
          window.print();
        },500);
      } else {
        window.print();
      }
    },
  }
}
</script>