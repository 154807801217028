<template>
  <div class="BonusTage">

    <v-container fluid>
      <v-row>
        <div class="col-md-12 pb-0">
          <h3>
            In den ersten 10 Tagen des Jahres {{year}} ...
          </h3>
          <p v-if="!isDatesGenerated">
            Für die ersten 10 Tage wurde bisher noch keine Ärzte Zuweisung erstellt.
          </p>
          <template v-else>
            <p v-if="dentistDays.length===0">
              Sie wurden aktuell nicht in den ersten 10 Tage des Jahres {{year}} eingeteilt.
            </p>
            <p v-else>
              Hinweis für die Urlaubsplanung: Sie wurden in den ersten 10 Tage des Jahres {{year}} eingeteilt.
            </p>
          </template>
        </div>
        <div class="col-md-6 pt-0">
          <div class="dateListing" v-if="dentistDays">
            <div class="listEntry" v-for="d in dentistDays" :key="d.id" :class="cssClass(d)">
              <div class="dateListingType">
                <DayType :type="d.type" ></DayType>
              </div>
              <div class="dateListingContent d-flex">
                {{ $helper.format(d.dateObject, 'dd.MM.yyyy (EEEE)') }}
                <v-spacer></v-spacer>
              </div>
            </div>
          </div>
        </div>

      </v-row>
    </v-container>
    
  </div>
</template>

<script>
import DayType from '../DayType.vue';
import DayState from '../DayState.vue';
export default {
  components: { DayType, DayState },
  mounted() {},
  props: {
    year: { required:true },
    days: { required:true, type: Array },
    dentist: { required:true, type: Object },
  },
  data: () => ({}),
  computed: {
    isDatesGenerated() {
      return this.days.filter(elem => elem.dentist_id!==null).length>0;
    },
    dentistDays() {
      return this.days.filter(elem => elem.dentist_id===this.dentist.id);
    },
    dayType_normal() { if(this.dentistDays) return this.dentistDays.filter(elem => elem.type==='normal').length; },
    dayType_weekend() { if(this.dentistDays) return this.dentistDays.filter(elem => elem.type==='weekend').length; },
    dayType_special() { if(this.dentistDays) return this.dentistDays.filter(elem => elem.type==='special').length; },
  },
  methods: {
    cssClass(data) {
      let result = {};
      if(data && data.type) {
        result['type-'+data.type] = true;
      }
      if(data && data.state) {
        result['state-'+data.state] = true;
      }
      return result;
    }
  }
}
</script>