<template>
  <form @submit.prevent="submit">
    <h2 class="mb-5">Automatische Vergabe für {{year}}</h2>
    <v-switch v-model="refreshExistingDays" label="Tage zurücksetzen" :hide-details="!refreshDentists && refreshExistingDays"></v-switch>
    
    <v-checkbox v-if="!refreshDentists && refreshExistingDays" v-model="removeExistingDentists" label="Bestehende Ärztezuordnungen zurücksetzen"></v-checkbox>
    
    <v-divider></v-divider>
    <v-switch v-model="refreshDentists" label="Arzt Zuordnung neu erstellen" hide-details></v-switch>
    <v-alert type="warning" class="mt-5" v-if="!refreshDentists && !refreshExistingDays">Sie sollten mindestens eine dieser Checkboxen anwählen, sonst wird es keine Änderung geben.</v-alert>
    <v-slide-y-transition>
      <v-row class="mt-5" v-if="refreshDentists">
        <div class="col-sm-12">
          Folgende Ärzte sollen bei der automatischen Zuordnung berücksichtigt werden:
          <v-checkbox v-for="item in items" :key="item.id" 
            v-model="selected" :value="item.id" dense hide-details>
            <template v-slot:label>
              {{ $helper.getDentistFullName(item) }}
              <DentistState class="ml-4" v-if="item.state==='inactive'" :dentist="item"></DentistState>
            </template>
          </v-checkbox>
        </div>
      </v-row>
    </v-slide-y-transition>

    
    <p class="text-right">
      <v-btn color="primary" :loading="loading" depressed type="submit">Übernehmen</v-btn>
    </p>

    <v-alert v-if="failError" class="mt-3 mb-0" type="error">{{failError}}</v-alert>
  </form>
</template>

<script>
import { doCreateYear, listDentist } from '../api';
import { mapGetters } from 'vuex';
import DentistState from './../DentistState.vue';
export default {
  components: { DentistState },
  props:{
    year : { required: true, type: Number }
  },
  data:() => ({
    loading:false,

    removeExistingDentists:true,
    refreshExistingDays:true,
    refreshDentists:true,

    errors: [],
    failError: "",
    selected: [],

    items: [],
  }),
  computed:{
    ...mapGetters({
      dayTypeItems : 'webapp/dayTypes',
      dayStateItems : 'webapp/dayStates',
    }),
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    reset() {
      this.errors = [];
    },
    loadItems() {
      if(this.loading) return;
      this.loading = true;

      listDentist()
        .then(response => {
          this.loading = false;
          this.items = response.data;
          this.selected = this.items.filter(elem => elem.state==='active').map(elem => elem.id);
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ title: "Fehler", message: "Beim Laden der Zahnarztpraxen ist ein Fehler aufgetreten.", type:"error" })
        });
    },
    async submit() {
      if(this.refreshDentists) {
        let message = "Durch die Neuvergabe werden alle bisherigen Zuordnungen für <strong>"+this.year+"</strong> unwiederbringlich überschrieben. Wollen Sie das wirklich tun?";
        if(! await document.webapp_messages.openConfirm({ title: "ACHTUNG", message: message, type:"error" })) {
          return;
        }
      }
      if(this.loading) return;
      this.loading = true;

      doCreateYear({ 
          year : this.year, 
          praxen: this.selected, 
          refreshExistingDays: this.refreshExistingDays,
          refreshDentists: this.refreshDentists,
          removeExistingDentists: this.removeExistingDentists,
        })
        .then(response => {
          this.loading = false;
          this.$emit('complete');
        })
        .catch(error => {
          this.loading = false;
          document.webapp_messages.openAlert({ message:"Bei der Berechnung der Daten ist es zu einem Fehler gekommen.", type:"error" });
        });
    },
  }
}
</script>