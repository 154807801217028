<template>
  <div class="zahnarztManagementWrap">
    <h1>Zahnarztpraxen</h1>
    
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details></v-text-field>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :search="search"
      :custom-filter="searchFilter"
      loading-text="Lade Daten ... Bitte warten Sie"
      @click:row="clickEntry"
    >

      <!-- top pagination -->
      <template v-slot:top="{ pagination, options, updateOptions }">
        <div class="d-flex flex-wrap dataTableTopBar">
          <v-btn class="mt-3" text @click="loadDentists" :loading="loading"><v-icon>mdi-reload</v-icon></v-btn>
          <v-btn class="mt-3 mx-1" @click="createItem()" color="primary" depressed>Zahnarztpraxis erstellen</v-btn>
          
          <v-btn class="mt-3 mx-1" text @click="changeYear(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-btn class="mt-3 mx-1" disabled label>{{year}}</v-btn>
          <v-btn class="mt-3 mx-1" text @click="changeYear(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
        
          <v-divider vertical></v-divider>
          <v-spacer></v-spacer>
          <v-data-footer class="noBorder" :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ $helper.getDentistFullName(item) }}
      </template>

      <template v-slot:[`item.attachedDaysCount`]="{ item }">
        <DiagramLinear :days="days" :dentist="item.id" :year="year"></DiagramLinear>
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <DentistState :dentist="item"></DentistState>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn depressed @click.stop="clickEntry(item)">Details</v-btn>
      </template>

    </v-data-table>

   
    <v-dialog v-model="formularModal" max-width="700">
      <v-card v-if="formularItem===null">
        <v-btn class="modalClose" @click="formularModal=false">&times;</v-btn>
        <v-card-title>Neue Praxis erstellen</v-card-title>
        <v-card-text v-if="formularModal">
          <ZahnarztPraxisFormular @complete="formularComplete"></ZahnarztPraxisFormular>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-btn class="modalClose" @click="formularModal=false">&times;</v-btn>
        <v-card-title>Praxis bearbeiten</v-card-title>
        <v-card-text v-if="formularModal">
          <ZahnarztPraxisFormular @complete="formularComplete" :data="formularItem"></ZahnarztPraxisFormular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { listDentist, readYearDates } from '../api';
import ZahnarztPraxisFormular from './ZahnarztPraxisFormular.vue';
import DiagramLinear from './DiagramLinear.vue';
import DentistState from './../DentistState.vue';
export default {
  props:{},
  components: { ZahnarztPraxisFormular, DiagramLinear, DentistState },
  mounted() {
    this.year = (new Date()).getFullYear();
    this.loadDentists();
    this.loadDays();
  },
  data:() => ({
    loading: false,
    data: [],
    search: '',
    headers: [
      { value: 'name', text: 'Name' },
      { value: 'company_city', text: 'aus' },
      { value: 'attachedDaysCount', text: 'Zugewiesen' },
      { value: 'state', text: 'Status' },
      { value: 'actions', text: '', align:'right' }
    ],
    itemsPerPage: 10,

    formularModal: false,
    formularItem: null,

    days: [],
    year: 2000,
  }),
  computed:{
    filteredData() {
      let result = this.data;
      if(this.days.length>0) {
        result.forEach(dentist => {
          dentist.attachedDaysCount = this.days.filter(day => day.dentist_id === dentist.id).length;
        });
      }
      return result;
    },
    
  },
  methods:{
    clickEntry(item) {
      this.$router.push({ name:'admin_zahnarztpraxen_single', params: { id: item.id} });
    },
    searchFilter(value, search, item) {
      let searchChunks = search.toLocaleLowerCase().split(' ');
      let results = searchChunks.map(elem => 
        item.firstname.toLocaleLowerCase().indexOf(elem) > -1
        || item.lastname.toLocaleLowerCase().indexOf(elem) > -1
        || item.company_city.toLocaleLowerCase().indexOf(elem) > -1
      );
      return results.every(x => x===true);
    },
    formularComplete() {
      this.formularModal = false;
      this.formularItem = null;
      this.loadDentists();
    },
    createItem() {
      this.formularModal = true;
      this.formularItem = null;
    },
    loadDentists() {
      if(this.loading) return;
      this.loading = true;
      
      listDentist()
        .then(response => {
          this.loading = false;
          this.data = response.data;
        })
        .catch(() => {
          this.loading = false;
        })
    },
    loadDays() {
      readYearDates(this.year)
        .then(response => {
          this.days = response.data;
        })
        .catch(error => {})
    },
    changeYear(direction) {
      this.year = this.year+direction;
      this.loadDays();
    }
  }
}
</script>