<template>
  <v-footer padless fixed>
    <v-card class="flex" flat tile color="grey lighten-2">
      <v-card-text class="py-2 white--text text-center">
        <router-link :to="{name:'home'}" custom v-slot="{ href }">
          <v-btn text :href="href"><v-icon small>mdi-home</v-icon></v-btn>
        </router-link>
        <router-link :to="{name:'legal_notice'}" custom v-slot="{ href }">
          <v-btn text :href="href">Impressum</v-btn>
        </router-link>
        <router-link :to="{name:'privacy_police'}" custom v-slot="{ href }">
          <v-btn text :href="href">Datenschutzerklärung</v-btn>
        </router-link>
        <app-version></app-version>
        <router-link :to="{name:'login'}" custom v-slot="{ href }">
          <v-btn text :href="href"><v-icon small>mdi-account-circle</v-icon></v-btn>
        </router-link>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import AppVersion from './AppVersion.vue';
export default {
  components: {
    AppVersion,
  },
}
</script>